import { PlusOutlined } from '@ant-design/icons';
import { Button, Dropdown, Input, Space, Table, TablePaginationConfig, Tree } from 'antd';
import type { FilterValue, Key } from 'antd/es/table/interface';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { contragentKey, getEmptyContractorShort } from '../../Models/IContragent';
import tokenActions, { AccessItem } from '../../actions/tokenActions';
import { useContragentsLocations } from '../../actions/useContragentsLocations';
import { useContragentsTags } from '../../actions/useContragentsTags';
import LayoutComponent from '../../shared/layout_component';
import TableLoading from '../../shared/table_loading';
import ContragentListColumns from './ContragentListColumns';
import ContragentListModal from './ContragentListModal';
import { sizes } from '../../shared/sizes';
import { AntTreeNodeProps } from 'antd/es/tree';
import MobileContragentListFilter from './MobileContragentListFilter';
import { MenuProps } from 'antd/lib';
import MobileContragentListRemoveContragent from './MobileContragentListRemoveContragent';
import { useContragentsDb } from '../../actions/useContragentsDB';
import { ContractorShort } from '../../ApiDirectory';
import { useTranslation } from 'react-i18next';
import { localeKeys } from '../../i18n/localeKeys';
import { useNomenclaturesTable } from '../../actions/useNomenclaturesTable';
import { useRegionList } from '../../actions/useRegionList';
import { uniq, uniqBy } from 'lodash';


interface IDocumentType {
    id?: string;
    name?: string;
}

interface IContragents {
    comment?: string | React.ReactNode | undefined;
    contacts?: React.ReactNode | undefined;
    created_at?: string;
    doc_date?: string | undefined;
    doc_number?: string | undefined;
    email?: string | null;
    id?: string;
    inn?: string;
    legal_address?: string | null;
    name?: string;
    ogrn?: string | null;
    phone?: string | null;
    postal_address?: string | null;
    rating?: number;
    rating_label?: string;
    related_user?: string;
    row_number?: number;
    type?: IDocumentType;
    updated_at?: string;
    nomenclatures?: { nomenclature_id: string, region_ids: string[] }[]
}

interface IContractorTitle {
    key: string;
    title: string | React.ReactNode;
    data: IContragents;
    nomenclatures: string[];
    comment: string | React.ReactNode | null;
    address: string;
    contact: React.ReactNode | null;
}

interface ILocation {
    key: string;
    title: React.ReactNode;
    children?: IContractorTitle[];
    icon?: any;
}


const ContragentList: FunctionComponent = () => {
    const isMobile = window.outerHeight < sizes.mobile;

    const queryClient = useQueryClient();
    const [ search, setSearch ] = useState( '' );
    const [ searchTags, setSearchTags ] = useState( '' );
    const [ searchLocations, setSearchLocations ] = useState( '' );
    const [ searchRating, setSearchRating ] = useState( '' );
    const [ page, setPage ] = useState( 1 );
    const [ perPage, setPerPage ] = useState( isMobile ? 100000 : 15 );
    const [ filters, setFilters ] = useState<{ location?: string[], rating?: string[], tags?: string[] }>( {} );

    const [ selectedNomenclatures, setSelectedNomenclatures ] = useState<string[]>( [] );
    const [ selectedLocations, setSelectedLocations ] = useState<string[]>( [] );
    const [ selectedRating, setSelectedRating ] = useState<string[]>( [] );

    const {
        getAllContragents,
        loadAllContragents,
        getCountContragents,
        isLoadingContragents,
        pageContragents,
        totalCountContragents,
        getFiltersTypes,
        getFiltersRating,
        filterTypes,
        filterRating,
    } = useContragentsDb();

    const { regions } = useRegionList()
    const { nomenclatures } = useNomenclaturesTable();

    const [ isOpenModalView, setIsModalOpenView ] = useState<ContractorShort>( getEmptyContractorShort() );
    const [ isOpenModal, setIsModalOpen ] = useState<ContractorShort>( getEmptyContractorShort() );

    function onChangeTable(
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: any,
        extra: any,
    ) {
        console.log( { pagination, filters, sorter, extra } );
        setPage( pagination.current ?? 1 );
        setFilters( filters as { [x: string]: string[] } );
    }

    useEffect( () => {
        (async () => {
            try {
                await loadAllContragents();
            } catch ( e ) {
                console.log( e );
            }
            await getCountContragents( { search } );
            await getAllContragents( { page: page, perPage: perPage } );
            await getFiltersRating();
            await getFiltersTypes();
        })();
    }, [] );

    useEffect( () => {
        (async () => {
            await getAllContragents( {
                page: page,
                perPage: perPage,
                search,
                nomenclatures: selectedNomenclatures,
                locations: selectedLocations,
            } );
            await getCountContragents( { search, nomenclatures: selectedNomenclatures, locations: selectedLocations } );
        })();
    }, [ page, search, selectedNomenclatures, selectedLocations, perPage ] );

    ///////////////////isMobile tree logic///////////////////
    const [ contractorsList, setContractorsList ] = useState<ILocation[]>( [] );
    const [ expandedKeys, setExpandedKeys ] = useState<string[]>( [] );
    const [ isModalOpenMobileFilter, setIsModalOpenMibileFilter ] = useState( false );

    const [ isOpenRemoveContragent, setIsOpenRemoveContragent ] = useState<ContractorShort | undefined>( undefined );

    const [ defaultFilter, setDefaultFilter ] = useState<boolean>( true );

    const regionsFilter = useMemo( () => {
        return regions.map( e => ({ value: e.id ?? '', label: e.title ?? '' }) );
    }, [ regions ] );

    const nomenclaturesFilter = useMemo( () => uniqBy( nomenclatures
        ?.rows
        ?.filter( e => e.row_type == 'nomenclature' )
        ?.map( e => ({
                value: e.nomenclature_id ?? '',
                label: e.title ?? '',
            }),
        ) ?? [], 'value' ), [ nomenclatures ] );

    const geDropdowntItems = ( id: string ): MenuProps['items'] => {
        const delet = [ {
            key: '3',
            label: (
                <a onClick={ () => setIsOpenRemoveContragent( pageContragents.find( ( item ) => item.id === id ) ?? getEmptyContractorShort() ) }>
                    { t( localeKeys.shared.deleteBtn ) }
                </a>
            ),
            disabled: tokenActions.contragents !== AccessItem.EDIT ?? false,
        } ];
        const edit = [ {
            key: '1',
            label: (
                <a onClick={ () => {
                    setIsModalOpen( pageContragents.find( ( item ) => item.id === id ) ?? getEmptyContractorShort() );
                } }>
                    { t( localeKeys.shared.editBtn ) }
                </a>
            ),
            disabled: tokenActions.contragents !== AccessItem.EDIT ?? false,
        } ];
        const view = [ {
            key: '2',
            label: (
                <a onClick={ () => {
                    setIsModalOpenView( pageContragents.find( ( item ) => item.id === id ) ?? getEmptyContractorShort() );
                } }>
                    { t( localeKeys.shared.viewBtn ) }
                </a>
            ),
        } ];
        if ( tokenActions.contragents === AccessItem.EDIT ) return [ ...edit, ...delet ];
        if ( tokenActions.contragents === AccessItem.VIEW ) return [ ...delet, ...view ];
    };

    const onExpand = ( keys: Key[], key2: any ) => {

        const target = key2.nativeEvent.target;
        if ( target.classList.contains( 'ant-space-item' ) && target.innerHTML.trim() === '. . .' ) {
            return;
        }
        if ( key2.event === 'select' ) {
            key2.expanded = key2.selected;
            keys = [ ...keys, ...expandedKeys ];
            if ( keys.length === 0 ) keys.push( key2.node.key );
        }

        //автоскрол
        if ( key2.expanded ) {
            /* setTimeout(() => {
                            key2?.nativeEvent?.target?.scrollIntoView({ behavior: "smooth", block: "start" })
                        }, 300) */
            setTimeout( () => {
                const target = key2?.nativeEvent?.target;
                if ( target ) {
                    const targetPosition = target.getBoundingClientRect().top + window.pageYOffset;
                    const offsetPosition = targetPosition - 15;
                    window.scrollTo( {
                        top: offsetPosition,
                        behavior: 'smooth',
                    } );
                }
            }, 300 );
        }

        let newListKey: any[] = [ ...keys ];

        if ( !expandedKeys.includes( key2.node.key ) ) {
            //проверяем открыт/закрыт (по наличию ключа)
            //если не найден
            const key = keys.map( ( item: any ) => {
                const parts = item.split( '-' );
                const firstDigit = parts.length > 1 ? parseInt( parts[1], 10 ) : null;
                const secondDigit = parts.length > 2 ? parseInt( parts[2], 10 ) : null;
                return { firstDigit, secondDigit };
            } );

            key.forEach( ( item1 ) => {
                if ( item1.secondDigit !== null ) {
                    //проверяем второй уровень есть ли
                    const levelLength = contractorsList.find( ( item2 ) => item2.key === `level-${ item1.firstDigit }` )?.children?.length ?? 0;
                    if ( levelLength ) {
                        for ( let index = 0; index < levelLength; index++ ) {
                            if ( !newListKey.includes( `level-${ item1.firstDigit }-${ index }` ) ) {
                                newListKey.push( `level-${ item1.firstDigit }-${ index }` );
                            }
                        }
                    }
                } else {
                    //нету второго уровня
                    if ( !newListKey.includes( `level-${ item1.firstDigit }` ) ) {
                        newListKey.push( `level-${ item1.firstDigit }` );
                    }
                }


            } );
        } else {
            //если уже открыт и ключ найден
            const parts = key2.node.key.split( '-' );
            const firstDigit = parts.length > 1 ? parseInt( parts[1], 10 ) : null;
            const secondDigit = parts.length > 2 ? parseInt( parts[2], 10 ) : null;
            if ( firstDigit !== null && secondDigit !== null ) {
                newListKey = expandedKeys.filter( ( item ) => !(item === `level-${ firstDigit }-` || item.startsWith( `level-${ firstDigit }-` )) );
            } else {
                newListKey = expandedKeys.filter( ( item ) => !(item === `level-${ firstDigit }`) );
            }
        }
        setExpandedKeys( [ ...newListKey ] );
    };

    useEffect( () => {
        if ( isMobile ) {
            if ( regions.length && pageContragents ) {
                const sortContractors: { [key: string]: number } = {
                    'Зеленый': 3,
                    'Желтый': 2,
                    'Черный': 1,
                    'Без рейтинга': 0,
                };
                const newListContractors: ILocation[] = [ '', ...regions.map( e => e.id ?? '' ) ]
                    .filter( regionId => {
                        if ( regionId == '' ) return true;

                        if ( filters.location?.length ) {
                            const hasInFilters = filters.location?.includes( regionId );
                            return !!hasInFilters;
                        }

                        return true;
                    } )
                    .map( ( regionId: string, index ) => {
                        let region = regions.find( e => e.id == regionId );
                        if ( regionId == '' ) {
                            region = {
                                id: '',
                                title: 'Все регионы',
                            }
                        }
                        const contractItem: IContragents[] = pageContragents
                            .filter( contragent => {

                                let flagLocation = true;
                                let flagTags = true;
                                let flagRating = true;

                                if ( filters.location?.length ) {
                                    flagLocation = false;
                                    for ( const nomenclature of contragent.nomenclatures ?? [] ) {
                                        const regions = nomenclature.region_ids ?? [];

                                        for ( const filterRegionId of filters.location ) {
                                            if ( regions.includes( filterRegionId ) ) {
                                                flagLocation = true;
                                            }
                                        }
                                    }
                                }

                                if ( filters.rating?.length ) {
                                    flagRating = false;
                                    for ( const rating of filters.rating ) {
                                        if ( contragent.rating == Number( rating ) ) {
                                            flagRating = true;
                                        }
                                    }
                                }

                                if ( filters.tags?.length ) {
                                    flagTags = false;
                                    for ( const tag of filters.tags ) {
                                        if ( contragent.nomenclatures?.find( e1 => e1.nomenclature_id == tag ) ) {
                                            flagTags = true;
                                        }
                                    }
                                }


                                return flagLocation && flagTags && flagRating;
                            } )
                            .filter( ( contractor ) => {
                                return contractor.nomenclatures?.find( nomenclature => {
                                    if ( regionId == '' ) return true;
                                    return nomenclature.region_ids?.includes( regionId );
                                } )
                            } );

                        ////сортировка
                        contractItem.sort( ( a, b ) => {
                            const aLabel = a.rating_label ?? t( localeKeys.contragentsListScreen.ratings.withoutRating );
                            const bLabel = b.rating_label ?? t( localeKeys.contragentsListScreen.ratings.withoutRating );

                            const aSortOrder = sortContractors[aLabel] ?? Number.MAX_SAFE_INTEGER;
                            const bSortOrder = sortContractors[bLabel] ?? Number.MAX_SAFE_INTEGER;

                            // Первое условие сортировки по rating_label
                            if ( aSortOrder !== bSortOrder ) {
                                return bSortOrder - aSortOrder;
                            }
                            return (a.row_number ?? Number.MAX_SAFE_INTEGER) - (b.row_number ?? Number.MAX_SAFE_INTEGER);
                        } );

                        ////фильтр
                        const childContractors: IContractorTitle[] = contractItem
                            .map( ( item, index2, arr ) => {

                                const nomenclatures2 = item.nomenclatures?.map( item => nomenclatures?.rows?.find( e3 => e3.nomenclature_id == item.nomenclature_id ) ?? {} ) ?? []

                                const color = item.rating_label === 'Желтый' ? 'yellow' : item.rating_label === 'Зеленый' ? 'green' : item.rating_label === 'Черный' ? 'black' : item.rating_label === 'Без рейтинга' ? 'white' : '';
                                const classChild = arr.length === 1 ? 'contragent-first-child-contragent-last-child' : index2 === 0 ? 'contragent-first-child' : index2 === arr.length - 1 ? 'contragent-last-child' : 'contragent-central-child';
                                const classLastContragentInfo = index2 === arr.length - 1 ? 'contragent-last-info' : '';
                                const render2level =
                                    <div
                                        className={ `tree-contragent level2 color-${ color } ${ classChild }` }
                                    >
                                        <div>
                                            <p className="tree-contragent-title">{ item.name }</p><p
                                            className="tree-contragent-title grey">{ t( localeKeys.contragentsListScreen.inn ) }: { item.inn }</p>
                                        </div>
                                        <Dropdown menu={ { items: geDropdowntItems( item.id ?? '' ) } }>
                                            <a onClick={ ( e ) => {
                                                e.preventDefault();
                                            } }>
                                                <Space>
                                                    . . .
                                                </Space>
                                            </a>
                                        </Dropdown>
                                    </div>;

                                const render3level = <div className={ `tree-contragent level3 color-${ color }` }>
                                    <div className="tree-contragent-text-title">
                                        <img className={ `tree-contragent-img` } src="/layout/Toolbox.svg" alt=""/>
                                        <p className="tree-contragent-text title-grey"> { t( localeKeys.contragentsListScreen.level3.works ) }</p>
                                    </div>
                                    <p className="tree-contragent-text-tags">
                                        { nomenclatures2?.map( ( item2, index ) => `${ item2.title ?? 'Неизвестная номенклатура' }` ).join( ', ' ) }
                                    </p>
                                    <div className="tree-contragent-text-title ">
                                        <img className={ `tree-contragent-img` } src="/layout/ChatText.svg" alt=""/>
                                        <p className="tree-contragent-text title-grey">{ t( localeKeys.contragentsListScreen.level3.comments ) }</p>
                                    </div>
                                    <div className="text-comments"
                                         dangerouslySetInnerHTML={ { __html: item.comment as JSX.Element } }/>
                                    <div className="tree-contragent-text-title">
                                        <img className={ `tree-contragent-img` } src="/layout/MapPin.svg" alt=""/>
                                        <p className="tree-contragent-text title-grey">{ t( localeKeys.contragentsListScreen.level3.address ) }</p>
                                    </div>
                                    <p className="tree-contragent-text-adress">{ item.legal_address ?? '' } { item.postal_address ?? '' }</p>
                                    <div className="tree-contragent-text-title "><img
                                        className={ `tree-contragent-img` }
                                        src="/layout/AddressBook.svg"
                                        alt=""/><p
                                        className="tree-contragent-text title-grey">{ t( localeKeys.contragentsListScreen.level3.contacts ) }</p>
                                    </div>
                                    <p className={ `text-contacts ${ classLastContragentInfo }` }
                                       dangerouslySetInnerHTML={ { __html: item.contacts as JSX.Element } }/>
                                </div>;

                                return {
                                    key: `level-${ index }-${ index2 }`,
                                    title: render2level,
                                    nomenclatures: nomenclatures2.map( e => e.title ?? 'Неизвестная номенклатура' ),
                                    data: {
                                        ...item,
                                        rating_label: item.rating_label ?? t( localeKeys.contragentsListScreen.ratings.withoutRating ),
                                    },
                                    comment: item.comment ?? null,
                                    address: item.legal_address ?? t( localeKeys.contragentsListScreen.level3.withoutAddress ),
                                    contact: item.contacts ?? null,
                                    children: [ {
                                        ...item,
                                        title: render3level,
                                        children: undefined,
                                        selectable: false,
                                    } ],
                                    icon: ( { props }: any ) => {
                                        return <img className={ `tree-contragent-img` } src="/layout/Toolbox.svg"
                                                    alt=""/>;
                                    },
                                };

                            } );
                        const colors = {
                            noRaiting: 0,
                            yellow: 0,
                            green: 0,
                            black: 0,

                        };
                        ////фильтрация

                        if ( childContractors ) {
                            childContractors.forEach( ( item ) => {
                                if ( item.data.rating_label === 'Желтый' ) colors.yellow = colors.yellow + 1;
                                if ( item.data.rating_label === 'Без рейтинга' ) colors.noRaiting = colors.noRaiting + 1;
                                if ( item.data.rating_label === 'Зеленый' ) colors.green = colors.green + 1;
                                if ( item.data.rating_label === 'Черный' ) colors.black = colors.black + 1;
                            } );
                        }

                        const render1level = <div className="wrapper-item">
                            <div className={ `tree-contragent level1` }>{ region?.title ?? 'Неизвестный регион' }</div>
                            <div className={ `wrapper-item-circle` }>
                                { colors.green === 0 ? null :
                                    <div className="circle circle-green">{ colors.green }</div> }
                                { colors.yellow === 0 ? null :
                                    <div className="circle circle-yellow">{ colors.yellow }</div> }
                                { colors.noRaiting === 0 ? null :
                                    <div className="circle circle-grey">{ colors.noRaiting }</div> }
                                { colors.black === 0 ? null :
                                    <div className="circle circle-black">{ colors.black }</div> }
                            </div>

                        </div>;
                        return {
                            key: `level-${ index }`,
                            title: render1level,
                            children: [ ...childContractors ],
                            icon: ( { props }: any ) => <img className={ `tree-contragent-img` }
                                                             src="/layout/Toolbox.svg"
                                                             alt=""/>,
                        };
                    } );
                console.log( { newListContractors } );
                setContractorsList( [ ...newListContractors ] );
            }

        }
    }, [ pageContragents, isMobile, filters, isLoadingContragents ] );


    /////filter default///////
    useEffect( () => {
        if ( isMobile && defaultFilter && !filters.rating ) {
            setFilters( {
                rating: [ '2', '3', '0' ],
            } );
        }
    }, [] );

    (window as any).contragentsPage = {
        page,
        pageContragents,
        contractorsList,
        isMobile,
        totalCountContragents,
        isOpenModal,
        isOpenModalView,
        filters,
        selectedTags: selectedNomenclatures,
        selectedLocations,
        selectedRating,
    };

    const { t } = useTranslation();


    return (
        <LayoutComponent
            title={ t( localeKeys.contragentsListScreen.title ) }
            className="ContragentList"
            onAction={ () => {
                setIsModalOpen( getEmptyContractorShort( '-1' ) );
            } }
        >
            {
                <div className={ `wrap_table ${ isMobile ? 'wrap_table-mobile' : '' }` } style={ { maxWidth: '100%' } }>
                    <div className={ `contragent-filter ${ isMobile ? 'mobile-filter' : '' }` }>
                        <Input
                            placeholder={ t( localeKeys.contragentsListScreen.placeholderSearch ) }
                            value={ search }
                            onChange={ ( e ) => setSearch( e.target.value ) }
                            allowClear
                        />
                        { isMobile ?
                            <img onClick={ () => setIsModalOpenMibileFilter( true ) }
                                 className={ `contragent-filter-icon` }
                                 src="/layout/FilterContragent.svg" alt=""/> : null }
                    </div>
                    <br/>
                    <br/>
                    { isLoadingContragents &&
                        !pageContragents.length &&
                        !search &&
                        !searchTags &&
                        !searchLocations && <TableLoading animate={ true } offsetTop={ false }/> }
                    {/*{JSON.stringify(contragentsLocation?.data)}*/ }
                    { !!((!!pageContragents.length || Object.values( filters ).length)) && (
                        <>
                            { isMobile ? (pageContragents.length > 0 ?
                                    (<Tree
                                        treeData={ contractorsList }
                                        onSelect={ onExpand }
                                        onExpand={ onExpand }
                                        expandedKeys={ expandedKeys }
                                        /*    titleRender={(item) => <MemoTooltip title={item.title as any}>{item.title as any}</MemoTooltip>} */
                                        virtual={ false }
                                        switcherIcon={ ( props: AntTreeNodeProps ) =>
                                            props.expanded ? (
                                                <img src={ '/images/close.svg' }
                                                     className={ `tree-contragent-img-arr open` } alt={ 'close' }/>
                                            ) : (
                                                <img src={ '/images/close.svg' } className={ `tree-contragent-img-arr` }
                                                     alt={ 'close' }/>
                                            )
                                        }
                                    />) : <>{ t( localeKeys.contragentsListScreen.noData ) }</>) :
                                (<Table
                                    className={ 'table_active' }
                                    rowKey={ 'id' }
                                    columns={
                                        ContragentListColumns( {
                                            filtersLocation: regionsFilter,
                                            filtersNomenclatures: nomenclaturesFilter,
                                            filtersRating: filterRating,
                                            onView: ( id ) => setIsModalOpenView( pageContragents.find( ( item ) => item.id === id ) ?? getEmptyContractorShort() ),
                                            onEdit: ( id ) => setIsModalOpen( pageContragents.find( ( item ) => item.id === id ) ?? getEmptyContractorShort() ),
                                            onArchive: async ( id ) => {
                                                setIsOpenRemoveContragent( pageContragents.find( ( item ) => item.id === id ) ?? getEmptyContractorShort() );
                                            },
                                            onRestore: () => {
                                            },
                                            status: 'active',
                                            filterState: {
                                                tags: {
                                                    value: selectedNomenclatures,
                                                    setValue: setSelectedNomenclatures,
                                                },
                                                location: {
                                                    value: selectedLocations,
                                                    setValue: setSelectedLocations,
                                                },
                                                rating: {
                                                    value: selectedRating,
                                                    setValue: setSelectedRating,
                                                },
                                            },
                                            filterSearch: {
                                                tags: {
                                                    value: searchTags,
                                                    setValue: ( text ) => setSearchTags( text ),
                                                },
                                                location: {
                                                    value: searchLocations,
                                                    setValue: ( text ) => setSearchLocations( text ),
                                                },
                                                rating: {
                                                    value: searchRating,
                                                    setValue: ( text ) => setSearchRating( text ),
                                                },
                                            },
                                            t,
                                        } ) as any
                                    }
                                    onChange={ onChangeTable }
                                    dataSource={ (pageContragents).map( ( e, index ) => ({
                                        ...e,
                                        number: index + 1
                                    }) ) }
                                    size={ 'large' }
                                    pagination={ {
                                        locale: { items_per_page: '' },
                                        total: totalCountContragents,
                                        defaultCurrent: page,
                                        pageSize: perPage,
                                        position: [ 'bottomLeft', 'bottomRight' ],
                                        showSizeChanger: true,
                                        pageSizeOptions: [ '15', '30', '50', '100' ],
                                        onChange: ( page, pageSize ) => {
                                            setPage( page );
                                            setPerPage( pageSize );
                                        },
                                    } }
                                    loading={ isLoadingContragents }
                                    onRow={ ( _ ) => ({
                                        onClick: () => {
                                            // if (row.id) {
                                            //     // open modal
                                            //     setIsModalOpen(row.id);
                                            // }
                                        },
                                    }) }
                                    scroll={ { y: window.innerHeight - 250 } }
                                />) }
                        </>
                    ) }
                </div>
            }

            { !isLoadingContragents && (pageContragents.length == 0 && contractorsList.length == 0) &&
                (!Object.values( filters ).length && selectedLocations.length == 0 && selectedNomenclatures.length == 0 && selectedRating.length == 0) && (
                    <>
                        <div className={ 'no_contragents' }>
                            <div
                                style={ {
                                    background: 'url(./../images/no_projects.svg), 50% / cover no-repeat',
                                    backgroundSize: '100%',
                                    width: '54px',
                                    height: '62px',
                                    mixBlendMode: 'multiply',
                                } }
                            ></div>
                            { tokenActions.contragents == AccessItem.EDIT && (
                                <>
                                    <div className={ 'text' }
                                         dangerouslySetInnerHTML={ { __html: t( localeKeys.contragentsListScreen.emptyCreate ).replaceAll( '\n', '<br/>' ) } }>
                                    </div>
                                    <Button type={ 'primary' }
                                            onClick={ () => setIsModalOpen( getEmptyContractorShort( '-1' ) ) }>
                                        { ' ' }
                                        <PlusOutlined/> { t( localeKeys.contragentsListScreen.addContragentBtn ) }
                                    </Button>
                                </>
                            ) }
                            { tokenActions.createProject == AccessItem.NONE && (
                                <>
                                    <div className={ 'text' }>{ t( localeKeys.contragentsListScreen.empty ) }</div>
                                </>
                            ) }
                        </div>
                    </>
                ) }

            <ContragentListModal
                localItem={ isOpenModal.id ? isOpenModal : isOpenModalView }
                close={ () => {
                    setIsModalOpen( getEmptyContractorShort() );
                    setIsModalOpenView( getEmptyContractorShort() );
                } }
                isOpen={ isOpenModal.id != '' || isOpenModalView.id != '' }
                onlyView={
                    isOpenModalView.id != '' ||
                    [ AccessItem.VIEW_ONLY, AccessItem.VIEW, AccessItem.VIEW_ALL ].includes( tokenActions.contragents )
                }
                onSuccess={ async () => {
                    await queryClient.invalidateQueries( contragentKey );
                    setPage( 1 );
                    await loadAllContragents();
                    await getCountContragents( {
                        page: 1,
                        perPage: perPage,
                        search,
                        nomenclatures: selectedNomenclatures,
                        locations: selectedLocations,
                    } );
                    await getAllContragents( {
                        page: 1,
                        perPage: perPage,
                        search,
                        nomenclatures: selectedNomenclatures,
                        locations: selectedLocations,
                    } );
                } }
                types={ filterTypes }
                rating={ filterRating }
            />
            <MobileContragentListFilter
                isOpen={ isModalOpenMobileFilter }
                close={ () => setIsModalOpenMibileFilter( false ) }
                filtersLocation={ regionsFilter }
                filtersRating={ filterRating }
                filtersNomenclatures={ nomenclaturesFilter }
                setDefaultFilter={ setDefaultFilter }
                defaultFilter={ defaultFilter }
                setFilter={ setFilters }
            />
            {
                !!isOpenRemoveContragent ?
                    <MobileContragentListRemoveContragent
                        isOpen={ !!isOpenRemoveContragent }
                        close={ async () => {
                            setIsOpenRemoveContragent( undefined );
                            await loadAllContragents();
                            await getAllContragents( { page: page, perPage: perPage } );
                            await getCountContragents( { search } );
                        } }
                        contragent={ isOpenRemoveContragent }
                    />
                    : null
            }
        </LayoutComponent>
    );
};
export default ContragentList;
