import { ListGrid } from 'cheetah-grid';
import { CellDefine, HeaderCellDefine } from 'cheetah-grid/list-grid/layout-map/api';
import { Moment } from 'moment';
import tokenActions from '../../actions/tokenActions';
import { TableRecord } from '../canvas_table/components/TableRecord';
import { getTableBody, TableMode } from './table_body/table_body';
import { ContractGroupedListItem, ProjectError, ProjectSimpleError } from '../../ApiEpo';
import { getStorePinned } from '../../shared/updStorePinned';
import { wDataHelpers } from '../../shared/wDataHelpers';
import { ContractorList, ContractorShort, TableRow } from '../../ApiDirectory';
import { getTableHeaders } from './table_header/table_headers';

export interface CellOpen {
  record: TableRecord;
  col: number;
  row: number;
  cell: any;
  columnKey: string;
  isModalAdd: boolean;
  isModalApproveBudget: boolean;
  isModalContragent: boolean;
  isModalSendApprove: boolean;
  isModalInvoice: boolean | string;
  isModalWayBill: boolean | string;
  isModalBill: boolean | string;
  isModalSettings: boolean;
  isModalAddContract: boolean;
  isModalHistory: boolean;
  isModalSelectContractors: boolean;
  openEdit: boolean;
  onCancelEdit?: ( value: string ) => void;
  timeOpen?: Moment;
}

export function getExcludesColumns() {
  const res: (keyof TableRecord)[] = [];

  if ( tokenActions.budgetPlan == 'NONE' ) {
    res.push(
      'cns_budget_plan_size',
      'cns_budget_plan_price',
      'cns_budget_plan_sum_wat',
      'cns_budget_plan_far',
      'cns_budget_plan_far2',
    );
  }

  if ( tokenActions.budgetFact == 'NONE' ) {
    res.push( 'cns_budget_fakt_size', 'cns_budget_fakt_price', 'cns_budget_fakt_sum_wat', 'cns_budget_fakt_far' );
  }

  if ( tokenActions.planeDates == 'NONE' ) {
    res.push( 'cns_plane_date_start', 'cns_plane_date_end' );
  }

  if ( tokenActions.contracts == 'NONE' && tokenActions.contractsDates == 'NONE' ) {
    res.push( 'cns_contact_date', 'cns_contracts' );
  }

  return res;
}

export interface WDataHelpers {
  bgColorLvl5: {
    get: () => string,
    set: ( val: string ) => void,
    reset: () => void
  },
  columnTitle: {
    get: () => string,
    set: ( val: string ) => void
    reset: () => void
  },
}

export interface WData {
  rows: TableRecord[];
  rows2: TableRecord[];
  rows3: TableRecord[];
  rows4: TableRecord[];
  rows5: TableRecord[];
  resultRows: TableRecord[];
  errors: ProjectError[];
  errorsSimple: ProjectSimpleError[];
  errorsTableView: { [x: string]: { [x: string]: string } }
  grid: ListGrid<TableRecord>;
  isInit: boolean;
  expandedLevel1: string[];
  expandedLevel2: string[];
  expandedLevel3: string[];
  expandedLevel4: string[];
  cellOpen?: CellOpen;
  moveCell?: { col: number; row: number; record: TableRecord; event?: any };
  excludedColumns: string[];
  resultRowValues: { [x: string]: string };
  tableMode: TableMode;
  textInput?: string;
  setRefresh: ( val: number ) => void;
  loadDataPassport: ( params: { showLoaded: boolean } ) => Promise<void>;
  body: CellDefine<TableRecord>[][];
  dedlainDays: number;
  hasAllowMoveOfApprove: boolean;
  struct: {
    getTableHeaders: () => HeaderCellDefine<TableRecord>[][];
    getTableBody: ( mode: TableMode, headers: CellDefine<TableRecord>[][] ) => CellDefine<TableRecord>[][];
    getRowsResult: () => TableRecord[];
  };
  refresh: {
    firstCalc: () => void;
    refreshStatusRecords: () => void;
    refreshFar: () => void;
  };
  globalActions: {
    isModalErrors: boolean;
    isModalErrorView: boolean | string;
    isModalAddManyContracts: boolean | string;
    isModalAddInvoice: boolean | string;
    isModalAddWayBills: boolean | string;
    isModalAddBills: boolean | string;
    isModalContractsAgreement: boolean;
    isModalContragentView: false | string;
  };
  pinned: {
    numberPinned: boolean
    titlePinned: boolean
    unitPinned: boolean
  },
  helpers: WDataHelpers,
  db: {
    dbContractors: ContractorShort[],
    loadAllContragents: () => Promise<ContractorList | undefined>,
    contractsAgreement: ContractGroupedListItem[],
    nomenclaturesStruct: TableRow[]
  },
  calc: {
    hasContractsAgreement: boolean
  },
  refreshStatusRecords: () => void,
}


export function getWData(): WData {
  return (window as any).canvasT ?? {
    rows: [],
    rows2: [],
    rows3: [],
    rows4: [],
    rows5: [],
    resultRows: [],
    isInit: false,
    expandedLevel1: [],
    expandedLevel2: [],
    expandedLevel3: [],
    expandedLevel4: [],
    cellOpen: undefined,
    excludedColumns: getExcludesColumns(),
    hasAllowMoveOfApprove: false,
    // [
    // "cns_title",
    // "cns_ed_izm",
    // "cns_budget_mark_sum_wat",
    // "cns_budget_plan_size",
    // "cns_budget_plan_price",
    // "cns_budget_plan_sum_wat",
    // "cns_budget_plan_far",
    // "cns_budget_plan_far2",
    // "cns_budget_fakt_size",
    // "cns_budget_fakt_price",
    // "cns_budget_fakt_sum_wat",
    // "cns_budget_fakt_far",
    // "cns_contact_date",
    // "cns_contact_contragent",
    // "cns_cantact_contragent",
    // ],
    resultRowValues: {
      cns_budget_mark_sum_wat: '',
      cns_budget_plan_sum_wat: '',
      cns_budget_plan_far2: '',
      cns_budget_fakt_size: '',
    },
    tableMode: TableMode.VIEW,
    textInput: undefined,
    setRefresh: ( val ) => {
    },
    loadDataPassport: () => Promise.resolve(),
    refreshStatusRecords: () => {
    },
    body: [],
    dedlainDays: 0,
    globalActions: {
      isModalErrors: false,
      isModalAddManyContracts: false,
      isModalAddInvoice: false,
      isModalAddWayBills: false,
      isModalAddBills: false,
      isModalContractsAgreement: false,
      isModalErrorView: false,
      isModalContragentView: false,
    },
    pinned: getStorePinned(),
    helpers: wDataHelpers,
    db: {
      dbContractors: [],
      loadAllContragents: () => Promise.resolve(undefined),
      nomenclaturesStruct: [],
      contractsAgreement: [],
    },
    calc: {
      hasContractsAgreement: false
    },
    errors: [],
    errorsSimple: [],
    errorsTableView: {},
    grid: undefined as any,
    moveCell: undefined,
    struct: {
      getTableHeaders: () => [],
      getRowsResult: () => [],
      getTableBody: () => [],
    },
    refresh: {
      firstCalc: () => {
      },
      refreshStatusRecords: () => {
      },
      refreshFar: () => {
      }
    }
  }/* as WData*/;
}

(window as any).canvasT = getWData();
