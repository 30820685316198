import { message, Modal, notification } from 'antd';
import { io, Socket } from 'socket.io-client';
import { IRowAdded, mapRowAdded2TableRecord } from '../../Models/IRowAdded';
import { getValueStore } from '../../shared/store';
import {
  getEmptyTableRecord,
  RecordStatus,
  TableRecord,
  TableRecordChangeStatus,
  TableRecordContract,
  TableRecordUpdIO,
} from '../canvas_table/components/TableRecord';
import { allowMoveApproveBudget, allowSendApproveBudget } from './allowEditCell';
import {
  approveRecords,
  disapproveRows,
  refreshRowAllowSendBudgetUp,
  refreshRowStatusDown,
  refreshStatusRecords,
} from './approveRow';
import { refreshFar } from './calcLocalValueRecord';
import { firstCalc } from './firstCalc';
import { getResultRows } from './getResultRows';
import { getWData } from './getWData';
import { callEventBus, getLoadDataInfo, getPassportCache, loadData, reloadErrors } from './loadData';
import { getTableBody, TableMode } from './table_body/table_body';
import { getTableHeaders } from './table_header/table_headers';
import {
  LoadDataBill2,
  LoadDataContractors,
  LoadDataInvoices2,
  LoadDataWellBill2,
  SyncDataResponsibles,
} from '../../Models/LoadData';
import { dateFormatter, parseDate } from '../../shared/dateFormat';
import { createDetailLines } from './loadDataDocuments';
import { hostSocket } from '../../shared/api_client';
import tokenActions from '../../actions/tokenActions';

export function getSocket(id: string = '') {
  return io(hostSocket ?? '', {
    extraHeaders: { Authorization: `Bearer ${getValueStore('token')}` },
    query: {
      project_id: id,
      // token: `Bearer ${getValueStore('token')}`,
    },
    path: '',
    // reconnectionAttempts: process.env.REACT_APP_DEV == 'true' ? 3 : undefined,
    //TODO пока откл, при потери связи может занова отправить если что-то заполнено
    //ackTimeout: 10000,
    //retries: 3
  });
}

export const syncData = {
  socket: (null as any) as Socket,
  async connect(id: string) {
    const id2 = window.location.pathname.split('/passport/')[1];

    this.socket = getSocket(id == '' ? id2 : id).connect();

    this.socket.on('project_joined', () => {
      // message.success('Соединение с сервером установлено.')
    });

    this.socket.on('disconnect', () => {
      this.modalMessageError();
      console.log('disconnect', this.socket.id);
    });

    this.socket.on('connect_error', () => {
      console.log('connect_error');
      //TODO пока откл, при потери связи может заново отправить если что-то заполнено
      // this.socket.connect();
      // this.socket.emit("project_join", {project_id: id});
    });
    this.socket.on('row_errors', (value: { description: string }[]) => {
      console.log('row_errors', value);
      setTimeout(() => {
        notification.destroy();

        for (let i = 0; i < value.length; i++) {
          notification.error({
            message: 'Ошибка',
            description: value[i].description,
          });
        }
      }, 1000);

      loadData(getPassportCache().id ?? '');
    });

    this.socket.on('row_added', (value: IRowAdded | IRowAdded[]) => {
      if (value.hasOwnProperty('length')) {
        for (let i = 0; i < (value as any).length; i++) {
          rowsAdded((value as any)[i]);
        }
      } else {
        rowsAdded((value as any).row ?? (value as any));
      }

      (window as any)?.callbackAddMany?.();
      firstCalc();
    });

    this.socket.on('rows_added', (value: IRowAdded[]) => {
      for (let i = 0; i < value.length; i++) {
        rowsAdded(value[i]);
      }
      firstCalc();
    });

    function rowsAdded(value: IRowAdded) {
      const record = mapRowAdded2TableRecord(value);
      console.log({ record });

      const index1 = getWData().rows.findIndex((e) => e.cns_id.indexOf('new_') === 0);
      if (index1 > -1) {
        getWData().rows.splice(index1, 1, record);
      }
      const index2 = getWData().rows2.findIndex((e) => e.cns_id.indexOf('new_') === 0);
      if (index2 > -1) {
        const oldRec = getWData().rows2[index2];
        getWData().rows2.splice(index2, 1, {
          ...oldRec,
          ...record,
          cns_title: record.cns_title ?? oldRec.cns_title,
          cns_level: record.cns_level ?? oldRec.cns_level,
          cns_row_type: record.cns_row_type ?? oldRec.cns_row_type,
        });
      }
      const index3 = getWData().rows3.findIndex((e) => e.cns_id.indexOf('new_') === 0);
      if (index3 > -1) {
        getWData().rows3.splice(index3, 1, record);
      }

      if (index1 == -1 && index2 == -1 && index3 == -1) {
        switch (record.cns_row_type) {
          case 'section':
            getWData().rows.push(record);
            break;
          case 'work_type':
            getWData().rows2.push(record);
            break;
          case 'nomenclature':
            getWData().rows3.push(record);
            break;
        }
      }

      getWData().resultRows = getResultRows();
      getWData().grid.records = getWData().resultRows;
      getWData().grid.invalidate();
    }

    function rowUpdated(value: TableRecordUpdIO) {
      console.log('row_updated', value);

      if (value.row_type == 'project') {
        return;
      }

      let record = getEmptyTableRecord();
      let index = -1;
      switch (value.row_type) {
        // case 'project':
        //   index = getWData().rows.findIndex((e) => e.cns_section_id === value.section_id) ?? getEmptyTableRecord();
        //   record = getWData().rows[index];
        //   break;
        case 'section':
          index = getWData().rows.findIndex((e) => e.cns_section_id === value.section_id) ?? getEmptyTableRecord();
          record = getWData().rows[index];
          break;
        case 'work_type':
          index =
            getWData().rows2.findIndex((e) => `${e.cns_section_id}_${e.cns_group_id}` === `${value.section_id}_${value.type_id}`) ??
            getEmptyTableRecord();
          record = getWData().rows2[index];
          break;
        case 'nomenclature':
          console.log('1');
          index = getWData().rows3.findIndex((e) => `${e.cns_section_id}_${e.cns_group_id}_${e.cns_nomenclature_id}` === `${value.section_id}_${value.type_id}_${value.nomenclature_id}`) ?? getEmptyTableRecord();
          record = getWData().rows3[index];
          break;
      }

      record.cns_number = `${value.row_number ?? record.cns_number ?? 0}`;

      if (value.unit) {
        record.cns_ed_izm = value.unit;
      }

      if (value.plan_budget) {
        record.cns_budget_plan_size = value.plan_budget[0] == null ? null : (`${value.plan_budget[0]}` as any);
        record.cns_budget_plan_price = value.plan_budget[1] == null ? null : (`${value.plan_budget[1]}` as any);
        record.cns_budget_plan_sum_wat = value.plan_budget[2] == null ? null : (`${value.plan_budget[2]}` as any);
        record.cns_budget_plan_far = `${value.plan_budget[3] || 0}`;
        record.cns_budget_plan_far2 = `${value.plan_budget[4] || 0}`;
      }

      if (value.fact_budget) {
        if (value.level == 3) {
          record.cns_budget_fakt_size = `${value.fact_budget[0]}`;
          record.cns_budget_fakt_price = `${value.fact_budget[1]}`;
        }

        record.cns_budget_fakt_sum_wat = `${value.fact_budget[2]}`;
        record.cns_budget_fakt_far = `${value.fact_budget[3]}`;
        record.cns_budget_fakt_last_change = `${value.fact_budget[4]}`;
      }

      if (value.contract_data?.[0]) {
        record.cns_contact_date_start = (parseDate(value.contract_data[0]));
      }

      if (value.contract_data?.[1]) {
        record.cns_contact_date_end = (parseDate(value.contract_data[1]));
      }

      if (value.contract_data?.[2]) {
        record.cns_contact_date = (parseDate(value.contract_data[2]));
      }

      if (value.graph?.filter(e => e)?.length) {
        record.cns_plane_date_start = (parseDate(value.graph[0]));
        record.cns_plane_date_end = (parseDate(value.graph[1]));

        if (value.level == 0) {
          getPassportCache().start_date = dateFormatter(parseDate(value.graph[0]));
          getPassportCache().end_date = dateFormatter(parseDate(value.graph[1]));
        }
      }

      if (value.contract_data?.[3].filter((e: any) => e)) {
        record.cns_contracts = [];
        for (let item of value.contract_data[3].filter((e: any) => e)) {
          record.cns_contracts.push({
            id: item.id,
            document_id: item.document_id,
            project_contract_data_id: item.project_contract_data_id,
            parent_id: record.cns_id,
            contragent: item.contractor,
            contragent_id: item.contractor_id,
            parent_contract_id: item.contract_id ?? null,
            doc_date: (parseDate(item.contract_date)),
            date_start: (parseDate(item.start_date)),
            date_end: (parseDate(item.end_date)),
            doc_number: item.contract_number,
            price: item.price,
            size: item.volume,
            sum_wat: item.amount,
            documents: item.files,
            files: item.files?.length ?? 0,
            status: item.status,
          });
        }
      }

      if (value.contract_data?.[4]) {
        record.cns_contractors = ((value.contract_data[4] as any) as LoadDataContractors[])
          ?.filter((e) => e)
          ?.map(e => ({
            label: e.name,
            value: e.id,
          }));
      }

      if (value.contract_data?.[5]) {
        if (!value.contract_data?.[5]?.length) {
          record.cns_responsibles = [];
        }

        // Проверка добавлена потому что бек присылает массив UID, а не массив объектов. Когда будет работать правильно можно будет убрать проверку
        if (value.contract_data?.[5]?.length && value.contract_data?.[5]?.[0]?.id) {
          record.cns_responsibles = ((value.contract_data[5] ?? []) as SyncDataResponsibles[])?.filter((e) => e)
            ?.map(e => ({
              id: e.id,
              full_name: e.full_name ?? (e as any).name,
            }));
        }
      }

      if (value.payment_invoices?.filter(e => e)) {
        if (value.payment_invoices?.[0]) {
          record.cns_invoice_count = value.payment_invoices?.[0];
        }

        if (value.payment_invoices?.[1]) {
          record.cns_invoice_date = (parseDate(value.payment_invoices?.[1]));
        }

        if (value.payment_invoices?.[2]) {
          record.cns_invoice_size = value.payment_invoices?.[2];
        }

        if (value.payment_invoices?.[3]) {
          record.cns_invoice_price = value.payment_invoices?.[3];
        }

        if (value.payment_invoices?.[4]) {
          record.cns_invoice_sum = value.payment_invoices?.[4];
        }

        if (value.payment_invoices?.[5]) {
          record.cns_invoice_status = value.payment_invoices?.[5];
        }

        if (value.payment_invoices?.[6]) {
          record.cns_invoices = value.payment_invoices?.[6].map((item: LoadDataInvoices2) => {
            const res: TableRecordContract = {
              id: item.id,
              contragent_id: item.contractor_id,
              contragent: item.contractor_name,
              parent_contract_id: item.contract_id ?? null,
              sum_wat: `${item.amount}`,
              size: `${item.volume}`,
              price: `${item.price}`,
              document_id: item.document_id,
              doc_date: (parseDate(item.invoice_date)),
              date_start: '',
              date_end: '',
              status: item.status,
              doc_number: item.invoice_number,
              parent_id: item.contract_id,
              project_contract_data_id: item.project_nomenclature_id,
              files: 0,
              documents: [],
            };
            return res;
          });
        }
      }

      if (value.delivered_completed?.filter(e => e)) {
        if (value.delivered_completed?.[0]) {
          record.cns_well_bill_count = value.delivered_completed?.[0];
        }

        if (value.delivered_completed?.[1]) {
          record.cns_well_bill_percent = value.delivered_completed?.[1];
        }

        if (value.delivered_completed?.[2]) {
          record.cns_well_bill_size = value.delivered_completed?.[2];
        }

        if (value.delivered_completed?.[3]) {
          record.cns_well_bill_delivery_sum = value.delivered_completed?.[3];
        }

        if (value.delivered_completed?.[4]) {
          record.cns_well_bill_delivery_date = parseDate(value.delivered_completed?.[4]);
        }

        if (value.delivered_completed?.[5]) {
          record.cns_well_bill_doc_number = value.delivered_completed?.[5];
        }

        if (value.delivered_completed?.[6]) {
          record.cns_way_bills = value.delivered_completed?.[6].map((item: LoadDataWellBill2) => {
            console.log({'sync: bill': item})
            const res: TableRecordContract = {
              id: item.id,
              contragent_id: item.contractor_id,
              contragent: '',
              parent_contract_id: item.contract_id ?? null,
              sum_wat: `${item.amount}`,
              size: `${item.volume}`,
              price: `${item.price}`,
              document_id: item.document_id,
              doc_date: parseDate(item.waybill_date),
              date_start: '',
              date_end: '',
              status: item.status,
              doc_number: item.waybill_number,
              parent_id: item.contract_id,
              project_contract_data_id: item.project_nomenclature_id,
              files: 0,
              documents: [],
              way_bill_doc_ids: item.way_bill_doc_ids ?? [],
            };

            return res;
          });

        }
      }
      if (value.plan_budget_deviation?.filter(e => e)) {
        if (value.plan_budget_deviation?.[0]) {
          record.cns_otkl_sum = value.plan_budget_deviation?.[0];
        }

        if (value.plan_budget_deviation?.[1]) {
          record.cns_otkl_percent = value.plan_budget_deviation?.[1];
        }
      }

      if (value.fact_payments?.filter(e => e)) {
        if (value.fact_payments?.[0] !== undefined) {
          record.cns_percent_payment = value.fact_payments?.[0];
        }

        if (value.fact_payments?.[1] !== undefined) {
          record.cns_payment_future = value.fact_payments?.[1];
        }
      }

      if (value.bills?.filter(e => e)) {
        // LoadDataBill
        if (value.bills?.[0] !== null && value.bills?.[0] !== undefined) {
          record.cns_bill_count = value.bills?.[0];
        }

        if (value.bills?.[1] !== null && value.bills?.[1] !== undefined) {
          record.cns_bill_number = value.bills?.[1];
        }
        if (value.bills?.[2] !== null && value.bills?.[2] !== undefined) {
          record.cns_bill_date = (parseDate(value.bills?.[2]));
        }
        if (value.bills?.[3] !== null && value.bills?.[3] !== undefined) {
          record.cns_bill_size = value.bills?.[3];
        }
        if (value.bills?.[4] !== null && value.bills?.[4] !== undefined) {
          record.cns_bill_price = value.bills?.[4];
        }
        if (value.bills?.[5] !== null && value.bills?.[5] !== undefined) {
          record.cns_bill_sum = value.bills?.[5];
        }
        if (value.bills?.[6]) {
          record.cns_bills = value.bills?.[6].map((item: LoadDataBill2) => {
            const res: TableRecordContract = {
              id: item.id,
              contragent_id: item.contractor_id,
              contragent: '',
              parent_contract_id: item.contract_id ?? null,
              sum_wat: `${item.amount}`,
              size: `${item.volume}`,
              price: `${item.price}`,
              document_id: item.document_id,
              doc_date: (parseDate(item.bill_date)),
              date_start: '',
              date_end: '',
              status: item.status,
              doc_number: item.bill_number,
              parent_id: item.contract_id,
              project_contract_data_id: item.project_nomenclature_id,
              files: 0,
              documents: [],
              way_bill_doc_ids: item.waybill_document_ids ?? [],
            };

            return res;
          });
        }
      }

      switch (record.cns_row_type) {
        case 'section':
          getWData().rows[index] = record;
          break;
        case 'work_type':
          getWData().rows2[index] = record;
          break;
        case 'nomenclature':
          getWData().rows3[index] = record;
          break;
      }

      record.cns_contracts.sort((a, b) => new Date(b.doc_date).getTime() - new Date(a.doc_date).getTime());
      record.cns_invoices.sort((a, b) => new Date(b.doc_date).getTime() - new Date(a.doc_date).getTime());
      record.cns_way_bills.sort((a, b) => new Date(b.doc_date).getTime() - new Date(a.doc_date).getTime());
      record.cns_bills.sort((a, b) => new Date(b.doc_date).getTime() - new Date(a.doc_date).getTime());


      // Когда поменялись значения бюджет плана обновить возможность отправить на согласование
      if (record.cns_row_type == 'nomenclature') {
        refreshRowAllowSendBudgetUp(record);

        console.log(`upd nom`);

        for (let i = getWData().rows4.length - 1; i >= 0; i--) {
          if (getWData().rows4[i].cns_nomenclature_id == record.cns_nomenclature_id) {
            getWData().rows4.splice(i, 1);
          }
        }

        for (let i = getWData().rows5.length - 1; i >= 0; i--) {
          if (getWData().rows5[i].cns_nomenclature_id == record.cns_nomenclature_id) {
            getWData().rows5.splice(i, 1);
          }
        }

        if (record.cns_contracts.length) {
          const lines = createDetailLines(record);

          getWData().rows4.push(...lines.filter(e => e.cns_row_type == 'contract'));
          if (tokenActions.visibleFiveLevel != 'NONE') {
            getWData().rows5.push(...lines.filter(e => e.cns_row_type == 'detail'));
          }
        }
      }

      refreshFar();
    }

    // подтвердили бюджет ориентир
    this.socket.on('guideline_budget_confirmed', async () => {
      await getWData().loadDataPassport({ showLoaded: true });
      getWData().grid.layout = {
        header: getTableHeaders(),
        body: getTableBody(TableMode.VIEW),
      };
      getWData().grid.invalidate();
    });

    this.socket.on('renamed', (value: { nomenclature_id?: string; section_id?: string; title: string }) => {
      console.log(`renamed ${value.section_id} ${value.nomenclature_id} ${value.title}`);
      getWData().rows = getWData().rows.map((e) => {
        if (e.cns_nomenclature_id === value.nomenclature_id || e.cns_section_id === value.section_id) {
          e.cns_title = value.title;
        }
        return e;
      });
      getWData().rows2 = getWData().rows2.map((e) => {
        if (e.cns_nomenclature_id === value.nomenclature_id) {
          e.cns_title = value.title;
        }
        return e;
      });
      getWData().rows3 = getWData().rows3.map((e) => {
        if (e.cns_nomenclature_id === value.nomenclature_id) {
          e.cns_title = value.title;
        }
        return e;
      });

      getWData().resultRows = getResultRows();
      getWData().grid.records = getWData().resultRows;
      getWData().grid.invalidate();
    });

    this.socket.on('row_deleted', (value: { row_number: string }) => {
      getWData().rows = getWData().rows.filter((e) => e.cns_number != value.row_number);
      getWData().rows2 = getWData().rows2.filter((e) => e.cns_number != value.row_number);
      getWData().rows3 = getWData().rows3.filter((e) => e.cns_number != value.row_number);

      getWData().resultRows = getResultRows();
      getWData().grid.records = getWData().resultRows;
      getWData().grid.invalidate();
    });

    this.socket.on('row_updated', (value: any) => {
      if (value.length) {
        value.forEach((e: TableRecordUpdIO) => {
          rowUpdated(e);
        });
      } else {
        rowUpdated(value);
      }

      firstCalc();
      refreshFar();

      getWData().resultRows = getResultRows();
      getWData().grid.records = getWData().resultRows;
      getWData().grid.invalidate();

      console.log('refreshContracts1');
      if ((window as any).resolveAdd) {
        (window as any).resolveAdd();
      }
      if ((window as any).resolveRemove) {
        (window as any).resolveRemove();
      }

      if ((window as any).resolveUpdate?.length) {
        (window as any).resolveUpdate.forEach((resolve: any) => {
          resolve();
        });
      }
    });

    this.socket.on('error_closed', async (value: any) => {
      await getWData().loadDataPassport({ showLoaded: false });
    });
    this.socket.on('error_canceled', async (value: any) => {
      await getWData().loadDataPassport({ showLoaded: false });
    });
    this.socket.on('passport_view_updated',async (value) => {
      await getWData().loadDataPassport({ showLoaded: false });
    });


    // пересчет номеров строк
    this.socket.on(
      'row_shifted',
      (
        values: {
          project_id: string;
          section_id: string;
          type_id: string;
          nomenclature_id: string;
          row_number: string;
        }[],
      ) => {
        values.forEach((value) => {
          const line = getWData().rows3.find(
            (e) => e.cns_id === `${value.section_id}_${value.type_id}_${value.nomenclature_id}`,
          );
          if (line) {
            line.cns_number = value.row_number;
          }
        });

        getWData().resultRows = getResultRows();
        getWData().grid.records = getWData().resultRows;
        getWData().grid.invalidate();
      },
    );

    // изменен статус бюджет плана
    this.socket.on('plan_budget_status_changed', (value: TableRecordChangeStatus) => {
      console.log('plan_budget_status_changed', value);
      for (let id of value?.nomenclature_ids ?? []) {
        const item = getWData().rows3.find((e) => e.cns_id === `${value.section_id}_${value.type_id}_${id}`);
        const group = getWData().rows2.find((e) => e.cns_id === `${value.section_id}_${value.type_id}`);
        const section = getWData().rows.find((e) => e.cns_id === `${value.section_id}`);
        if (item) {
          if (value.status == 1 || value.status == 'on_approval') {
            item.cns_status = RecordStatus.BUDGET_PLAN_UNDER_REVIEW;
            item.cns_allow_send_approve = false;
          }
          if (value.status == 2 || value.status == 'resolved') {
            item.cns_status = RecordStatus.BUDGET_PLAN_APPROVED;
            item.cns_allow_send_approve = false;
          }
          if (value.status == 3 || value.status == 'rejected') {
            item.cns_status = RecordStatus.BUDGET_PLAN_REJECTED;
            item.cns_allow_send_approve = allowSendApproveBudget(item);
          }
        } else if (group) {
          const items = getWData().rows3.filter((e) => e.cns_id.startsWith(`${value.section_id}_${value.type_id}_`));
          if (value.status == 1 || value.status == 'on_approval') {
            group.cns_status = RecordStatus.BUDGET_PLAN_UNDER_REVIEW;
            items.forEach((item) => (item.cns_status = RecordStatus.BUDGET_PLAN_UNDER_REVIEW));
            refreshRowStatusDown(group, 'send_approve');
          }
          if (value.status == 2 || value.status == 'resolved') {
            group.cns_status = RecordStatus.BUDGET_PLAN_APPROVED;
            items.forEach((item) => (item.cns_status = RecordStatus.BUDGET_PLAN_APPROVED));
            refreshRowStatusDown(group, 'approve');
          }
          if (value.status == 3 || value.status == 'rejected') {
            group.cns_status = RecordStatus.BUDGET_PLAN_REJECTED;
            items.forEach((item) => (item.cns_status = RecordStatus.BUDGET_PLAN_REJECTED));
            refreshRowStatusDown(group, 'reject');
          }
        } else if (section) {
          const items = getWData().rows2.filter((e) => e.cns_id.startsWith(`${value.section_id}_`));
          if (value.status == 1 || value.status == 'on_approval') {
            section.cns_status = RecordStatus.BUDGET_PLAN_UNDER_REVIEW;
            items.forEach((item) => (item.cns_status = RecordStatus.BUDGET_PLAN_UNDER_REVIEW));
            refreshRowStatusDown(section, 'send_approve');
          }
          if (value.status == 2 || value.status == 'resolved') {
            section.cns_status = RecordStatus.BUDGET_PLAN_APPROVED;
            items.forEach((item) => (item.cns_status = RecordStatus.BUDGET_PLAN_APPROVED));
            refreshRowStatusDown(section, 'approve');
          }
          if (value.status == 3 || value.status == 'rejected') {
            section.cns_status = RecordStatus.BUDGET_PLAN_REJECTED;
            items.forEach((item) => (item.cns_status = RecordStatus.BUDGET_PLAN_REJECTED));
            refreshRowStatusDown(section, 'reject');
          }
        }
      }

      for (let id of value.type_ids ?? []) {
        const group = getWData().rows2.find((e) => e.cns_group_id == id);
        const items = getWData().rows3.filter((e) => e.cns_group_id == id);

        if (group) {
          if (value.status == 1 || value.status == 'on_approval') {
            group.cns_status = RecordStatus.BUDGET_PLAN_UNDER_REVIEW;
            group.cns_allow_send_approve = false;
            group.cns_has_sended_approve = true;

            for (let item of items.filter((e) => allowMoveApproveBudget(e))) {
              item.cns_status = RecordStatus.BUDGET_PLAN_UNDER_REVIEW;
              item.cns_allow_send_approve = false;
              item.cns_has_sended_approve = true;
            }
          }
          if (value.status == 2 || value.status == 'resolved') {
            approveRecords([id]);
          }
          if (value.status == 3 || value.status == 'rejected') {
            disapproveRows([id]);
          }
        }

        refreshStatusRecords();
      }

      for (let id of value.section_ids ?? []) {
        const items = getWData().rows3.filter((e) => e.cns_section_id == id);
        for (let item of items) {
          if ((value.status == 1 || value.status == 'on_approval') && allowMoveApproveBudget(item)) {
            item.cns_status = RecordStatus.BUDGET_PLAN_UNDER_REVIEW;
            item.cns_allow_send_approve = false;
            item.cns_has_sended_approve = true;
          }
          if (
            (value.status == 2 || value.status == 'resolved') &&
            item.cns_status == RecordStatus.BUDGET_PLAN_UNDER_REVIEW
          ) {
            item.cns_status = RecordStatus.BUDGET_PLAN_APPROVED;
          }
          if (value.status == 3 || value.status == 'rejected') {
            item.cns_status = RecordStatus.BUDGET_PLAN_REJECTED;
            item.cns_allow_send_approve = allowSendApproveBudget(item);
          }
        }

        if (value.status == 1 || value.status == 'on_approval') {
          refreshRowStatusDown(getWData().rows.find((e) => e.cns_section_id == id)!, 'send_approve');
        }
        if (value.status == 2 || value.status == 'resolved') {
          refreshRowStatusDown(getWData().rows.find((e) => e.cns_section_id == id)!, 'approve');
        }
        if (value.status == 3 || value.status == 'rejected') {
          refreshRowStatusDown(getWData().rows.find((e) => e.cns_section_id == id)!, 'reject');
        }
      }

      if (value.type == 'project') {
        const items = getWData().rows3;
        for (let item of items) {
          if ((value.status == 1 || value.status == 'on_approval') && allowMoveApproveBudget(item)) {
            item.cns_status = RecordStatus.BUDGET_PLAN_UNDER_REVIEW;
            item.cns_allow_send_approve = false;
            item.cns_allow_send_approve = allowSendApproveBudget(item);
          }
          if (
            (value.status == 2 || value.status == 'resolved') &&
            item.cns_status == RecordStatus.BUDGET_PLAN_UNDER_REVIEW
          ) {
            item.cns_status = RecordStatus.BUDGET_PLAN_APPROVED;
          }
          if (
            (value.status == 3 || value.status == 'rejected') &&
            item.cns_status == RecordStatus.BUDGET_PLAN_UNDER_REVIEW
          ) {
            item.cns_status = RecordStatus.BUDGET_PLAN_REJECTED;
            item.cns_allow_send_approve = allowSendApproveBudget(item);
          }
        }

        const sections2 = getWData().rows.filter(section => getWData().rows3.find(r => r.cns_section_id == section.cns_id && r.cns_status == RecordStatus.BUDGET_PLAN_UNDER_REVIEW));
        console.log({ sections2 });
        for (let section of sections2) {
          if (value.status == 1 || (value.status == 'on_approval' && section.cns_allow_send_approve)) {
            refreshRowStatusDown(section, 'send_approve');
          }
          if (value.status == 2 || value.status == 'resolved') {
            refreshRowStatusDown(section, 'approve');
          }
          if (value.status == 3 || value.status == 'rejected') {
            refreshRowStatusDown(section, 'reject');
          }
        }
      }

      refreshStatusRecords();
      getWData().setRefresh(Math.random());
      getWData().resultRows = getResultRows();
      getWData().grid.layout = {
        header: getTableHeaders(),
        body: getTableBody(TableMode.VIEW),
      };
      getWData().grid.records = getWData().resultRows;
      getWData().grid.invalidate();
    });

    // бюджет план разблокирован
    this.socket.on('plan_budget_status_unlocked', (value: TableRecordChangeStatus) => {
      console.log('plan_budget_status_unlocked', value);
      for (let id of value?.nomenclature_ids ?? []) {
        const item = getWData().rows3.find((e) => e.cns_id === `${value.section_id}_${value.type_id}_${id}`);
        if (item && value.status == 0) {
          item.cns_status = RecordStatus.BUDGET_PLAN_REJECTED;
          item.cns_allow_send_approve = true;
        }
      }

      for (let id of value.type_ids ?? []) {
        const items = getWData().rows3.filter(
          (e) => e.cns_group_id === id && e.cns_status == RecordStatus.BUDGET_PLAN_APPROVED,
        );
        for (const item of items) {
          if (item && value.status == 0) {
            item.cns_status = RecordStatus.BUDGET_PLAN_REJECTED;
            item.cns_allow_send_approve = true;
          }
        }
      }

      for (let id of value.section_ids ?? []) {
        const items = getWData().rows3.filter(
          (e) => e.cns_section_id === id && e.cns_status == RecordStatus.BUDGET_PLAN_APPROVED,
        );
        for (const item of items) {
          if (item && value.status == 0) {
            item.cns_status = RecordStatus.BUDGET_PLAN_REJECTED;
            item.cns_allow_send_approve = true;
          }
        }
      }

      refreshStatusRecords();
      getWData().setRefresh(Math.random());
      getWData().resultRows = getResultRows();
      getWData().grid.records = getWData().resultRows;
      getWData().grid.invalidate();
    });

    this.socket.on('import_ended', async (_) => {
      await getWData().loadDataPassport({ showLoaded: true });
      message.info('Выполнен импорт в проект. Данные перезагружены.');
    });

    this.socket.on('error', async (value) => {
      let text = value?.errors ? JSON.stringify(value?.errors) : value.message;
      let code = value.code;

      if (code == 'SHOW_EXCEPTION') {
        return;
      }

      if (code == 'JOIN_EXCEPTION') {
        this.modalMessageError();
        return;
      }

      if (text == '{"title":["null value not allowed"]}') {
        text = 'Наименование обязательно для заполнения';
      }

      message.error(text);

      if ((window as any).rejectAdd) {
        (window as any).rejectAdd();
      }
      if ((window as any).rejectRemove) {
        (window as any).rejectRemove();
      }

      await reloadErrors();
      callEventBus('updated-errors')
    });

    this.socket.on('alert', async (value) => {
      let text = value?.errors ? JSON.stringify(value?.errors) : value.message;

      notification.warning({
        message: text,
      });

      await reloadErrors();
      callEventBus('updated-errors')
    });
    this.socket.on('error',async (value) => {
      let text = value?.errors ? JSON.stringify(value?.errors) : value.message;
      let code = value.code;

      if (code == 'SHOW_EXCEPTION') {
        notification.error({
          description: text,
          message: 'Данные не обновлены',
        });

        const origin = value.data?.origin;
        const section_id = value.data?.section_id;
        const group_id = value.data?.type_id;
        const nomenklature_id = value.data?.nomenclature_id;

        let item = getEmptyTableRecord();
        if (nomenklature_id) {
          const index = getWData().rows3.findIndex((e) => e.cns_nomenclature_id === nomenklature_id);
          if (index !== -1) {
            item = getWData().rows3[index];
          }
        } else if (group_id) {
          const index = getWData().rows2.findIndex((e) => e.cns_group_id === group_id);
          if (index !== -1) {
            item = getWData().rows2[index];
          }
        } else if (section_id) {
          const index = getWData().rows.findIndex((e) => e.cns_section_id === section_id);
          if (index !== -1) {
            item = getWData().rows[index];
          }
        }

        if (origin?.graph?.start_date) item.cns_plane_date_start = (parseDate(origin?.graph?.start_date));
        if (origin?.graph?.end_date) item.cns_plane_date_end = (parseDate(origin?.graph?.end_date));
        if (origin?.graph?.planning_date) item.cns_contact_date = (parseDate(origin?.graph?.planning_date));

        getWData().setRefresh(Math.random());
        getWData().resultRows = getResultRows();
        getWData().grid.records = getWData().resultRows;
        getWData().grid.invalidate();

        await reloadErrors();
        callEventBus('updated-errors')
      }
    });

    if (!this.socket.connected) {
      for (let i = 0; i < 10; i++) {
        await new Promise((resolve) => {
          setTimeout(resolve, 1000);
        });
        if (this.socket.connected) {
          break;
        }
      }
    }

    if (!this.socket.connected) {
      this.modalMessageError();
      //  message.error('Соединение с сервером потеряно. Пожалуйста, перезагрузите страницу.')
    }

    this.socket.emit('project_join', { project_id: id });


  },
  createNomeclatura(record: TableRecord, insertAfter?: string) {
    const payload: any = {
      section_id: record.cns_section_id,
      type_id: record.cns_group_id,
      title: record.cns_title,
      unit: record.cns_ed_izm ? record.cns_ed_izm : null,
    };

    if (insertAfter) {
      payload.insert_after = insertAfter;
    }

    this.socket.emit('add_nomenclature', payload);
  },
  createNomenclatures(records: TableRecord[], sectionId: string, typeId: string) {
    const payload: any = {
      section_id: sectionId,
      type_id: typeId,
      rows: records.map((_) => ({ title: null, unit: null })),
    };

    this.socket.emit('add_nomenclatures', payload);
  },
  createNomenclatures2(records: TableRecord[], sectionId: string, typeId: string, insertAfter?: string) {
    const payload: any = {
      section_id: sectionId,
      type_id: typeId,
      rows: records.map((e) => ({
        title: e.cns_title,
        unit: e.cns_ed_izm,
        plan_price: e.cns_budget_plan_price ? Number(e.cns_budget_plan_price ?? '0') : null,
        plan_volume: e.cns_budget_plan_size ? Number(e.cns_budget_plan_size ?? '0') : null,
      })),
    };

    if (insertAfter) {
      payload.insert_after = insertAfter;
    }

    this.socket.emit('add_nomenclatures', payload);
  },
  createGroup(record: TableRecord, isDeep = false) {
    this.socket.emit('add_type', {
      section_id: record.cns_section_id,
      title: record.cns_title,
      type_id: isDeep ? record.cns_group_id : undefined,
    });
  },
  createSection(record: TableRecord) {
    this.socket.emit('add_section', {
      title: record.cns_title || `Новый раздел ${getWData().rows.length}`,
    });
  },
  confirmBudgetMark() {
    this.socket.emit('guideline_budget_confirm', {});
  },
  updateCellValue(
    record: TableRecord,
    values: {
      guideline_amount?: number;
      plan_price?: number;
      plan_volume?: number;
      unit?: string;
    },
  ) {
    console.log('updateCellValue', values);
    this.socket.emit('update_row', {
      section_id: record.cns_section_id,
      type_id: record.cns_group_id,
      nomenclature_id: record.cns_nomenclature_id,
      value: {
        guideline_amount: values.guideline_amount,
        plan_price: values.plan_price ?? null,
        plan_volume: values.plan_volume ?? null,
        unit: values.unit ?? null,
      },
    });
  },
  async addContract(
    record: TableRecord,
    values: {
      contractor_id: string;
      contract_number: string;
      contract_date: string;
      price: number;
      volume: number;
      amount: number;
      file_ids: string[];
    },
  ) {
    console.log({ values });
    this.socket.emit('add_contract', {
      section_id: record.cns_section_id,
      type_id: record.cns_group_id,
      nomenclature_id: record.cns_nomenclature_id,
      contractor_id: values.contractor_id,
      contract_date: values.contract_date,
      contract_number: values.contract_number,
      price: values.price,
      volume: values.volume,
      amount: values.amount,
      file_ids: values.file_ids,
    });

    return new Promise((resolve, reject) => {
      (window as any).resolveAdd = resolve as any;
      (window as any).rejectAdd = reject;
    });
  },
  deleteContract(record: TableRecord, contractId: string) {
    this.socket.emit('delete_contract', {
      section_id: record.cns_section_id,
      type_id: record.cns_group_id,
      nomenclature_id: record.cns_nomenclature_id,
      contract_id: contractId,
    });

    return new Promise((resolve, reject) => {
      (window as any).resolveRemove = resolve as any;
      (window as any).rejectRemove = reject;
    });
  },
  renameRow(id: string, title: string, level: number) {
    this.socket.emit('rename', {
      id,
      title: title ? title : null,
      type: level === 1 ? 'section' : level === 2 ? 'work_type' : 'nomenclature',
    });
  },
  deleteRow(record: TableRecord) {
    switch (record.cns_row_type) {
      case 'section':
        this.socket.emit('delete_section', { section_id: record.cns_section_id });
        break;
      case 'work_type':
        this.socket.emit('delete_type', { type_id: record.cns_group_id, section_id: record.cns_section_id });
        break;
      case 'nomenclature':
        this.socket.emit('delete_nomenclature', {
          nomenclature_id: record.cns_nomenclature_id,
          type_id: record.cns_group_id,
          section_id: record.cns_section_id,
        });
        break;
    }
  },
  sendToApproveBudgetPlan(record: TableRecord) {
    console.log('sendToApproveBudgetPlan', record);
    let payload: any = {
      ids: [record.cns_nomenclature_id ?? record.cns_group_id ?? record.cns_section_id],
      section_id: record.cns_section_id,
      type_id: record.cns_group_id,
      status: 'on_approval',
      type:
        record.cns_row_type == 'project'
          ? 'project'
          : record.cns_row_type == 'section'
            ? 'section'
            : record.cns_row_type == 'work_type'
              ? 'work_type'
              : 'nomenclature',
      reason: null,
    };

    if (record.cns_row_type == 'work_type') {
      delete payload.type_id;
    }

    if (record.cns_row_type == 'section') {
      delete payload.section_id;
      delete payload.type_id;
    }

    if (record.cns_row_type == 'project') {
      delete payload.section_id;
      delete payload.type_id;
      delete payload.ids;
    }

    this.socket.emit('plan_budget_status', payload);
  },
  approveBudgetPlan(ids: string[], record: TableRecord, action: 'approved' | 'rejected') {
    console.log('approveBudgetPlan', ids, record, action);
    let type =
      record.cns_row_type == 'project'
        ? 'section'
        : record.cns_row_type == 'section'
          ? 'section'
          : record.cns_row_type == 'work_type'
            ? 'work_type'
            : 'nomenclature';
    if (action == 'rejected') {
      type =
        record.cns_row_type == 'project'
          ? 'section'
          : record.cns_row_type == 'section'
            ? 'work_type'
            : record.cns_row_type == 'work_type'
              ? 'nomenclature'
              : 'nomenclature';
    }
    const payload: any = {
      ids: ids,
      type_id: record.cns_group_id,
      section_id: record.cns_section_id,
      status: action,
      type: type,
      reason: null,
    };
    if (record.cns_row_type == 'work_type') {
      // delete payload.type_id;
    }
    if (record.cns_row_type == 'section') {
      delete payload.type_id;
    }
    if (record.cns_row_type == 'project') {
      delete payload.type_id;
      delete payload.section_id;
    }

    this.socket.emit('plan_budget_status', payload);
  },
  unlockBudgetPlan(record: TableRecord) {
    const type =
      record.cns_row_type == 'project'
        ? 'project'
        : record.cns_row_type == 'section'
          ? 'section'
          : record.cns_row_type == 'work_type'
            ? 'work_type'
            : 'nomenclature';
    this.socket.emit('plan_budget_unlock', {
      ids: [record.cns_nomenclature_id ?? record.cns_group_id ?? record.cns_section_id],
      type_id:
        type == 'nomenclature'
          ? record.cns_group_id
          : record.cns_row_type == 'work_type' && record.cns_level > 2
            ? record.cns_group_id
            : null,
      section_id: record.cns_section_id,
      type: type,
    });
  },
  updateDate(record: TableRecord, column: keyof TableRecord, append?: { single?: boolean }) {
    console.log('updateDate', record, column, append);

    if (record.cns_nomenclature_id) {
      const item: any = {
        nomenclature_id: record.cns_nomenclature_id,
        start_date: dateFormatter(parseDate(record.cns_plane_date_start), { placeholder: '', format: 'DD.MM.YYYY' }),
        end_date: dateFormatter(parseDate(record.cns_plane_date_end), { placeholder: '', format: 'DD.MM.YYYY' }),
        planning_date: dateFormatter(parseDate(record.cns_contact_date), { placeholder: '', format: 'DD.MM.YYYY' }),
      };

      if (!item.start_date || column == 'cns_plane_date_end') delete item.start_date;

      if (!item.end_date || column == 'cns_plane_date_start') delete item.end_date;

      if (!item.planning_date) delete item.planning_date;

      if (record.cns_row_type == 'nomenclature') {
        if (column == 'cns_plane_date_start') {
          delete item.end_date;
          delete item.planning_date;
        }

        if (column == 'cns_plane_date_end') {
          delete item.start_date;
          delete item.planning_date;
        }
      }

      const payload: any = {
        section_id: record.cns_section_id,
        type_id: record.cns_group_id,
        values: [item],
      };

      if (!record.cns_group_id) delete payload.cns_group_id;

      // if (payload.start_date || payload.end_date || payload.planning_date)
      this.socket.emit('update_graph_dates', payload);
    } else {
      const payload: any = {
        section_id: record.cns_section_id,
        type_id: record.cns_group_id,
        start_date: dateFormatter(parseDate(record.cns_plane_date_start), { placeholder: '', format: 'DD.MM.YYYY' }),
        end_date: dateFormatter(parseDate(record.cns_plane_date_end), { placeholder: '', format: 'DD.MM.YYYY' }),
      };

      if (append?.single) {
        payload.only_top_row = true;
      }

      if (!record.cns_group_id) delete payload.type_id;

      if (!record.cns_section_id) delete payload.section_id;

      if (!payload.start_date || column == 'cns_plane_date_end') delete payload.start_date;

      if (!payload.end_date || column == 'cns_plane_date_start') delete payload.end_date;

      if (record.cns_row_type == 'project') payload.project = true;

      if (payload.start_date || payload.end_date) this.socket.emit('update_top_graph_date', payload);
    }
  },
  modalMessageError() {
    Modal.destroyAll();
    if (process.env.REACT_APP_DEV != 'true') {
      notification.error({
        message: 'Ошибка',
        description: 'Соединение с сервером потеряно. Пожалуйста, перезагрузите страницу.',
      });
      // Modal.error({
      //   maskTransitionName: 'none',
      //   wrapClassName: 'modal-custom-com',
      //   closable: false,
      //   footer: null,
      //   title: 'Ошибка',
      //   content: 'Соединение с сервером потеряно. Пожалуйста, перезагрузите страницу.',
      //   afterClose: () => {
      //     console.log('afterClose');
      //   },
      //   onCancel: () => {
      //     console.log('onCancel');
      //   },
      // });
    }
  },
};
