import { FunctionComponent } from 'react';
import ContragentListModal from '../../../contragents/ContragentListModal';
import { useContragentsDb } from '../../../../actions/useContragentsDB';
import { getWData } from '../../../table_extends/getWData';

const ModalContragentView: FunctionComponent<{ isOpen: boolean, id: string | undefined }> = (props) => {
    const {
        filterTypes,
        filterRating,
    } = useContragentsDb();


    const localItem = getWData().db.dbContractors.find(e => e.id == props.id);

    return (
        <ContragentListModal
            isOpen={props.isOpen}
            close={() => {
                getWData().globalActions.isModalContragentView = false;
                getWData().setRefresh( Math.random() );
            }}
            onlyView={true}
            localItem={localItem ?? {}}
            onSuccess={() => {
            }}
            rating={filterRating}
            types={filterTypes}
        />
    );
};

export default ModalContragentView;
