import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import LayoutComponent from '../../shared/layout_component';
import { useTranslation } from 'react-i18next';
import { notification, Table, App, message } from 'antd';
import nomenclaturesColumns from './NomenclaturesListColumns';
import { useNomenclaturesTable } from '../../actions/useNomenclaturesTable';
import { TableRow } from '../../ApiDirectory';
import { NomenclaturesListModalPosition } from "./NomenclaturesListModalPosition";
import { directoryApi } from "../../shared/api_client";
import { getEntityTitle, getEntityType, LocalValueNomenclature } from './NomenclatureListHelpers';
import { usePricesLiteTable } from "../../actions/usePricesLiteTable";
import { getHeaderComponentHeight } from "../../shared/header_component";
import confirm from "antd/es/modal/confirm";
import { uniq } from "lodash";
import { useMeasureUnits } from "../../actions/useMeasureUnitList";

const NomenclaturesList: FunctionComponent = () => {
    const { t } = useTranslation();
    const {
        nomenclatures,
        nomenclaturesLoading,
        nomenclaturesError,
        nomenclaturesClearCache,
    } = useNomenclaturesTable();
    const { prices, pricesRegions, pricesLoading, pricesError, pricesClearCache } = usePricesLiteTable();
    const { measureUnits } = useMeasureUnits( { page: 1, per_page: 10000 } );

    const [ isOpen, setIsOpen ] = useState<{
        id: string | boolean;
        type: 'section' | 'work_type' | 'nomenclature',
        section_id?: string,
        type_id?: string,
        nomenclature_id?: string,
        nomenclatures: TableRow[]
    }>();
    const [ isOpenRename, setIsOpenRename ] = useState<{ id: string }>( { id: '' } )

    const [ isAdd, setIsAdd ] = useState<{
        section_id?: string,
        work_type_id?: string,
        isAdd: boolean
    }>( { isAdd: false } )

    const { notification } = App.useApp();

    const [ expandedRowKeys, setExpandedRowKeys ] = useState<string[]>( [] );
    const [ processSave, setProcessSave ] = useState<{
        id: string,
        region_id?: string,
        type: 'price' | 'unit' | 'title'
    }[]>( [] );

    const [ localValues, setLocalValues ] = useState<{ [nomenclature_id: string]: LocalValueNomenclature }>( {} );
    const unitVariants = uniq( [ ...measureUnits?.map( e => e.title ?? '' ) ?? [], ...Object.values( localValues ).map( e => e.unit ?? '' ) ] ).filter( e => e );

    const rows: (TableRow & { hasChildren: boolean })[] = useMemo( () => {
        const res = nomenclatures?.rows
            .map( row => {
                if ( row.row_type == 'section' ) {
                    const hasChildren = !!nomenclatures?.rows.find( row2 => row2.section_id == row.section_id && row2.row_type == 'work_type' );
                    return {
                        ...row,
                        hasChildren,
                    };
                }

                if ( row.row_type == 'work_type' ) {
                    const hasChildren = !!nomenclatures?.rows.find( row2 => `${row2.section_id}_${row2.type_id}` ==  `${row.section_id}_${row.type_id}` && row2.row_type == 'nomenclature' );
                    return {
                        ...row,
                        hasChildren,
                    };
                }

                return {
                    ...row,
                    hasChildren: false,
                };
            } )
            .filter( row => {
                if ( row.row_type == 'section' ) return true;

                if ( row.row_type == 'work_type' ) {
                    if ( expandedRowKeys.includes( row.section_id! ) ) {
                        return true;
                    }
                }

                if ( row.row_type == 'nomenclature' ) {
                    if ( expandedRowKeys.includes( `${row.section_id}_${row.type_id}` )) {
                        return true;
                    }
                }

                return false;
            } ) ?? []

        if ( isAdd.isAdd ) {
            if ( !isAdd.section_id && !isAdd.work_type_id ) {
                res.unshift( {
                    section_id: '-',
                    type_id: '-',
                    nomenclature_id: '-',
                    row_number: '-',
                    row_type: 'section',
                    title: '',
                    hasChildren: false,
                } )
            }

            if ( isAdd.section_id && !isAdd.work_type_id ) {
                const index = res.findIndex( e => e.section_id == isAdd.section_id && e.row_type == 'section' );
                res.splice( index + 1, 0, {
                    section_id: '-',
                    type_id: '-',
                    nomenclature_id: '-',
                    row_number: '-',
                    row_type: 'work_type',
                    title: '',
                    hasChildren: false,
                } )
            }

            if ( isAdd.section_id && isAdd.work_type_id ) {
                const index = res.findIndex( e => e.section_id == isAdd.section_id && e.type_id == isAdd.work_type_id && e.row_type == 'work_type' );
                res.splice( index + 1, 0, {
                    section_id: '-',
                    type_id: '-',
                    nomenclature_id: '-',
                    row_number: '-',
                    row_type: 'nomenclature',
                    title: '',
                    hasChildren: false,
                } )

            }
            setIsOpenRename( { id: '-' } )
        }

        return res;
    }, [ nomenclatures, expandedRowKeys, isAdd ] );

    useEffect( () => {
        const res: { [nomenclature_id: string]: LocalValueNomenclature } = {};

        if ( rows.length > 0 ) {
            rows.forEach( row => {
                if ( row.section_id == '-' ) {
                    res['-'] = {
                        title: '',
                        unit: '',
                        prices: [],
                    }
                } else {
                    if ( row.row_type == 'nomenclature' ) {
                        res[row.nomenclature_id!] = {
                            title: row.title ?? '',
                            unit: row.unit ?? '',
                            prices: prices.filter( e => e.nomenclature_id == row.nomenclature_id ).map( price => ({
                                price: price.price ?? 0,
                                region_id: price.region_id ?? '',
                            }) )
                        };
                    }
                    if ( row.row_type == 'work_type' ) {
                        res[row.type_id!] = {
                            title: row.title ?? '',
                            unit: '',
                            prices: [],
                        }
                    }
                    if ( row.row_type == 'section' ) {
                        res[row.section_id!] = {
                            title: row.title ?? '',
                            unit: '',
                            prices: [],
                        }
                    }
                }
            } );
        }

        setLocalValues( res );
    }, [ rows, pricesLoading, isAdd, isOpenRename ] );

    (window as any).nomenclaturesList = {
        expandedRowKeys,
        setExpandedRowKeys,
        rows,
        nomenclatures,
        nomenclaturesLoading,
        nomenclaturesError,
        isOpen,
        localValues,
        unitVariants,
    };

    const [ isOpenPopover, setIsOpenPopover ] = useState<{ id: string }>( { id: '' } )

    useEffect( () => {
        function onClick( event: MouseEvent ) {
            const target = event.target as HTMLElement;
            if ( target.closest( '.nomenclatures-table__popover' ) == null ) {
                setIsOpenPopover( { id: '' } );
            }
        }

        window.addEventListener( 'click', onClick );
        return () => window.removeEventListener( 'click', onClick );
    }, [] );

    return (
        <>
            <LayoutComponent
                title={ 'Справочник номенклатур' }
                className="NomenclaturesList"
                onAction={ ( type, payload ) => {
                    if ( type == 'create_nomenclature_section' ) {
                        setIsAdd( { isAdd: true } )
                        setIsOpenRename( { id: '-' } );
                    }
                } }
            >
                <div className="Nomenclatures__wrapper">
                    <Table
                        className={ 'nomenclatures-table' }
                        columns={ nomenclaturesColumns( {
                            units: unitVariants,
                            items: nomenclatures?.rows ?? [],
                            prices: prices,
                            regions: pricesRegions,
                            localValues: localValues,
                            isOpenRename: isOpenRename,
                            setIsOpenRename: setIsOpenRename,
                            t,
                            processSave: processSave,
                            isOpenPopover: isOpenPopover,
                            setIsOpenPopover: setIsOpenPopover,
                            setIsOpenMove: ( {
                                                 nomenclature_id,
                                                 section_id,
                                                 type_id
                                             } ) => setIsOpen( {
                                id: nomenclature_id,
                                nomenclature_id,
                                type: 'nomenclature',
                                section_id,
                                type_id,
                                nomenclatures: nomenclatures?.rows ?? [],
                            } ),
                            onEdit: ( type, section_id, type_id, nomenclature_id, region_id, value ) => {
                                if ( localValues[nomenclature_id ?? type_id ?? section_id ?? ''] ) {
                                    if ( type == 'title' ) {
                                        const newVal: LocalValueNomenclature = {
                                            ...localValues[nomenclature_id ?? type_id ?? section_id ?? ''],
                                            title: value ?? ''
                                        }
                                        setLocalValues( {
                                            ...localValues,
                                            [nomenclature_id ?? type_id ?? section_id ?? '']: newVal
                                        } );
                                    }

                                    if ( type == 'unit' ) {
                                        const newVal: LocalValueNomenclature = {
                                            ...localValues[nomenclature_id ?? ''],
                                            unit: value ?? ''
                                        }
                                        setLocalValues( { ...localValues, [nomenclature_id ?? '']: newVal } );
                                    }

                                    if ( type == 'price' ) {
                                        const oldVal = localValues[nomenclature_id ?? ''];
                                        let prices: {
                                            region_id: string,
                                            price: number
                                        }[] = JSON.parse( JSON.stringify( oldVal.prices ) );
                                        const indexPrice = prices.findIndex( item => item.region_id == region_id );
                                        if ( indexPrice != -1 ) {
                                            prices[indexPrice].price = Number( value ?? '0' );
                                        } else {
                                            prices.push( {
                                                region_id: region_id ?? '',
                                                price: Number( value ?? '0' )
                                            } );
                                        }
                                        const newVal: LocalValueNomenclature = {
                                            ...localValues[nomenclature_id ?? ''],
                                            prices: prices,
                                        }
                                        setLocalValues( { ...localValues, [nomenclature_id ?? '']: newVal } );
                                    }
                                }
                            },
                            onEditEnd: async ( type, section_id, type_id, nomenclature_id, region_id, value ) => {
                                const id = nomenclature_id ?? type_id ?? section_id ?? '';

                                if ( id == '-' ) {
                                    if (value == '') {
                                        setIsAdd({isAdd: false})
                                        setIsOpenRename({id: ''})
                                        nomenclaturesClearCache();
                                    } else {
                                        const row = rows.find( e => e.section_id == '-' )

                                        if ( row ) {
                                            setProcessSave( [ ...processSave, {
                                                id: id ?? '',
                                                type: type,
                                                region_id: region_id,
                                            } ] );

                                            if ( row.row_type == 'section' ) {
                                                await directoryApi.workSections.workSectionsCreate( { title: value ?? '' } )
                                                notification.success( { message: 'Раздел успешно добавлен' } );
                                            }
                                            if ( row.row_type == 'work_type' ) {
                                                const res = await directoryApi.workTypes.workTypesCreate( {
                                                    title: value ?? '',
                                                    sections: [ { section_id: isAdd.section_id } ]
                                                } )
                                                notification.success( { message: 'Подраздел успешно добавлен' } );


                                            }
                                            if ( row.row_type == 'nomenclature' ) {
                                                await directoryApi.nomenclatures.nomenclaturesCreate( {
                                                    title: value ?? '',
                                                    unit: 'шт',
                                                    sections: [ {
                                                        section_id: isAdd.section_id!,
                                                        type_id: isAdd.work_type_id!
                                                    } ]
                                                } )
                                                notification.success( { message: 'Номенклатура успешно добавлена' } );

                                                if ( !expandedRowKeys.includes( isAdd.work_type_id! ) ) {
                                                    setExpandedRowKeys( [ ...expandedRowKeys, `${isAdd.section_id}_${isAdd.work_type_id}` ] );
                                                }
                                            }

                                            setIsOpenRename( { id: '' } )
                                            setIsAdd( { isAdd: false } )
                                            setProcessSave( [ ...processSave ].filter( e => e.id != '-' ) )
                                            nomenclaturesClearCache();
                                        }
                                    }
                                } else {

                                    if ( localValues[id ?? ''] ) {
                                        setProcessSave( [ ...processSave, {
                                            id: id ?? '',
                                            type: type,
                                            region_id: region_id,
                                        } ] );

                                        try {

                                            if ( type == 'title' ) {
                                                if ( nomenclature_id ) {
                                                    await directoryApi.nomenclatures.nomenclaturesUpdate( nomenclature_id, {
                                                        title: value ?? '',
                                                        unit: localValues[id].unit,
                                                    } );
                                                } else if ( type_id ) {
                                                    await directoryApi.workTypes.workTypesUpdate( type_id, {
                                                        title: value ?? '',
                                                    } );
                                                } else if ( section_id ) {
                                                    await directoryApi.workSections.workSectionsUpdate( section_id, {
                                                        title: value ?? '',
                                                    } );
                                                }
                                                nomenclaturesClearCache();
                                            }

                                            if ( type == 'unit' ) {
                                                await directoryApi.nomenclatures.nomenclaturesUpdate( nomenclature_id!, {
                                                    unit: value ?? '',
                                                } );
                                                nomenclaturesClearCache();
                                            }

                                            if ( type == 'price' ) {
                                                const oldValue = prices.find( item => item.nomenclature_id == nomenclature_id && item.region_id == region_id )?.price;
                                                const newValue = localValues[nomenclature_id ?? ''].prices.find( item => item.region_id == region_id )?.price;

                                                if ( oldValue != newValue && !((oldValue == null) && (newValue == 0))) {
                                                    await directoryApi.nomenclaturePrices.nomenclaturePricesCreate( {
                                                        prices: [ {
                                                            nomenclature_id,
                                                            region_id,
                                                            price: Number( value ?? `0` ),
                                                        } ]
                                                    } );
                                                    pricesClearCache();
                                                }
                                            }
                                        } catch ( e ) {
                                            notification.error( { message: 'Не удалось выполнить запрос' } );
                                        }

                                        setProcessSave( [ ...processSave.filter( e => e.id != id && e.region_id != region_id) ] );
                                    }
                                }
                            },
                            onArchive: async ( section_id, type_id, nomenclature_id ) => {
                                confirm( {
                                    title: 'Удалить?',
                                    onOk: async () => {
                                        try {
                                            if ( nomenclature_id ) {
                                                await directoryApi.nomenclatures.nomenclaturesDelete( nomenclature_id );
                                            } else if ( type_id ) {
                                                await directoryApi.workTypes.workTypesDelete( type_id, {section_id, only_rel: (nomenclatures?.rows?.filter(e => e.row_type == 'work_type' && e.type_id == type_id)?.length ?? 0) > 1 ? 1 : 0 } );
                                            } else if ( section_id ) {
                                                await directoryApi.workSections.workSectionsDelete( section_id );
                                            }
                                            notification.success( { message: `${ getEntityTitle( getEntityType( section_id, type_id, nomenclature_id ) ) } ${ nomenclature_id ? 'удалена' : type_id ? 'удален' : 'удален' }` } );

                                        } catch ( e ) {
                                            notification.error( { message: 'Не удалось удалить запись' } );
                                        }
                                        nomenclaturesClearCache();
                                    }
                                } )
                            },
                            onRestore: ( id ) => {
                            },
                            onView: ( id ) => {
                            },
                            onAdd: async ( section_id, type_id ) => {
                                if ( !section_id && !type_id ) {
                                    setIsAdd( { isAdd: true } )
                                } else if ( section_id && !type_id ) {
                                    setIsAdd( { isAdd: true, section_id: section_id } )
                                } else if ( section_id && type_id ) {
                                    setIsAdd( { isAdd: true, section_id: section_id, work_type_id: type_id } )
                                }

                                nomenclaturesClearCache();
                            },
                            onAddNomenclature: () => {
                                setIsOpen( { type: 'nomenclature', id: true, nomenclatures: nomenclatures?.rows ?? [] } );
                            },
                            onMove: async ( direction, record ) => {
                                let items: TableRow[] = [ ...rows ];

                                if ( record.row_type == 'section' ) {
                                    items = rows.filter( item => item.row_type == 'section' )
                                }

                                if ( record.row_type == 'work_type' ) {
                                    items = rows.filter( item => item.section_id == record.section_id && item.row_type == 'work_type' )
                                }

                                if ( record.row_type == 'nomenclature' ) {
                                    items = rows.filter( item => item.type_id == record.type_id && item.row_type == 'nomenclature' )
                                }

                                const index = items.indexOf( record );

                                if ( direction == 'up' ) {
                                    items.splice( index - 1, 0, record )
                                    items.splice( index + 1, 1 )
                                } else {
                                    items.splice( index + 2, 0, record )
                                    items.splice( index, 1 )
                                }

                                // console.log(items.map(e => e.title))

                                if ( record.row_type == 'nomenclature' ) {
                                    await directoryApi.nomenclatures.sortCreate( {
                                        section_id: record.section_id ?? '',
                                        type_id: record.type_id ?? '',
                                        ids: items.map( e => e.nomenclature_id ?? e.type_id ?? e.section_id ?? '' )
                                    } )
                                }

                                if ( record.row_type == 'work_type' ) {
                                    await directoryApi.workTypes.sortCreate( {
                                        section_id: record.section_id ?? '',
                                        ids: items.map( e => e.nomenclature_id ?? e.type_id ?? e.section_id ?? '' )
                                    } )
                                }

                                if ( record.row_type == 'section' ) {
                                    await directoryApi.workSections.sortCreate( {
                                        ids: items.map( e => e.nomenclature_id ?? e.type_id ?? e.section_id ?? '' )
                                    } )
                                }

                                nomenclaturesClearCache();
                            },
                            setExpandedRowKeys,
                            expandedRowKeys,
                        } ) }
                        rowKey={ ( row, index ) => `${ row.nomenclature_id } ${ row.type_id } ${ row.section_id } ${ row.row_type } ${ index }` }
                        dataSource={ rows }
                        pagination={ false }
                        onRow={ ( record ) => ({
                            onClick: () => {
                                const id: string = record.nomenclature_id || record.type_id || record.section_id as string;
                                let fullId = '';

                                if ( record.row_type == 'section' ) {
                                    fullId = `${record.section_id}`;
                                } else if ( record.row_type == 'work_type' ) {
                                    fullId = `${record.section_id}_${record.type_id}`;
                                } else {
                                    fullId = `${record.section_id}_${record.type_id}_${record.nomenclature_id}`;
                                }

                                const isExpanded = expandedRowKeys.includes( fullId );

                                if ( record.row_type == 'section' ) {
                                    const workTypeIds = rows
                                        .filter( row => row.section_id == id && row.row_type == 'work_type' )
                                        .map( row => `${row.section_id}_${row.type_id}` );

                                    setExpandedRowKeys( isExpanded ? [ ...expandedRowKeys.filter( ( key ) => key !== fullId && !workTypeIds.includes(key ) ) ] : [ ...expandedRowKeys, fullId ] );
                                }

                                if ( record.row_type == 'work_type' ) {
                                    setExpandedRowKeys( isExpanded ? [ ...expandedRowKeys.filter( ( key ) => key !== fullId ) ] : [ ...expandedRowKeys, fullId ] );
                                }
                            },
                        }) }
                        scroll={ { y: `calc(100vh - ${ getHeaderComponentHeight() }px - 50px)` } }
                    />
                </div>
            </LayoutComponent>

            <NomenclaturesListModalPosition
                nomenclatures={nomenclatures?.rows ?? []}
                isOpen={ isOpen?.id ?? false }
                onClose={ () => setIsOpen( undefined ) }
                select_section_id={ isOpen?.section_id }
                select_work_type_id={ isOpen?.type_id }
                select_nomenclature_id={ isOpen?.nomenclature_id }
                onDone={ async ( state ) => {
                    console.log( { state } );
                    try {
                        if ( state.entity_id ) {
                            await directoryApi.nomenclatures.nomenclaturesUpdate( state.entity_id, {
                                title: state.title,
                                unit: state.unit,
                                sections: state.place.work_type_id.map( ( e, index ) => {
                                    const section_id = (e.split('_')[0] ?? e) as string;
                                    const type_id = (e.split('_')[1] ?? e) as string;
                                    return ({
                                        section_id: section_id,
                                        type_id: type_id,
                                    });
                                }),
                            } as any );
                        } else {
                            await directoryApi.nomenclatures.nomenclaturesCreate( {
                                title: state.title,
                                unit: state.unit,
                                sections: state.place.work_type_id.map( ( e, index ) => {
                                    const type_id = (e.split('_')[1] ?? e) as string;
                                    const section_id = (e.split('_')[0] ?? e) as string;
                                    return ({
                                        section_id: section_id,
                                        type_id: type_id,
                                    });
                                }),
                            } );
                        }


                        setIsOpen( undefined );
                        nomenclaturesClearCache();
                        notification.success( { 'message': 'Сохранено' } );
                    } catch ( e ) {
                        console.error( e );
                        notification.error( {
                            message: 'Ошибка',
                            description: (e as any)?.error?.message ?? 'Не удалось выполнить запрос'
                        } );
                    }
                } }
            />
        </>
    );
};


export default NomenclaturesList;
