import { ListGrid } from 'cheetah-grid';
import { getOffsetX } from '../../../shared/getOffsetX';
import { TableRecord } from '../../canvas_table/components/TableRecord';
import { getWData } from '../getWData';
import { IconsSizes } from '../table_body/icons_sizes';

export function checkOpenModalSelectContractors(
  record: TableRecord,
  columnKey: keyof TableRecord,
) {
  if (record.cns_row_type == 'nomenclature' && columnKey == 'cns_contractors') {

    return true;
  }

  return false;
}
