import React, { FunctionComponent } from 'react';
import { getErrorFieldPrefics, getTypeModalEnum, TypeModalEnum } from '../utils/getErrorFieldPrefics';
import dateFormat, { dateFormatter, numberFormatter } from '../../../../shared/dateFormat';
import { ProjectError } from '../../../../ApiEpo';
import { getLoadDataInfo } from '../../../table_extends/loadData';
import { getEmptyTableRecordContract, TableRecord, TableRecordContract } from '../TableRecord';
import DownloadFile from '../../../../shared/DownloadFile';
import { hostEpo } from '../../../../shared/api_client';

interface Line {
    id: string,
    title: string,
    size: string,
    price: string,
    sum: string,
    ed_izm: string,
    doc_id: string
    doc_id2: string
    doc_name: string
    hasErrorSize: boolean,
    hasErrorSum: boolean,
    hasErrorPrice: boolean,
}

const ModalErrorLines: FunctionComponent<{
    errors: ProjectError[],
    contractId: string,
    contractLines: TableRecordContract[],
    nomenclatures: TableRecord[],
    hasErrorSizeWithDates: boolean,
    typeError: TypeModalEnum
}> = (props) => {

    const errors = props.errors ?? [];
    const loadData = getLoadDataInfo();

    const preficsErrorField = getErrorFieldPrefics(props.typeError);
    const docType = getTypeModalEnum(props.typeError);
    let typeLabel = 'Контракт';

    const contract = props.contractLines[0] ?? getEmptyTableRecordContract();

    if (props.contractLines.length) {
        const lines: Line[] = props.contractLines
            .map((e, index) => {
                if (props.typeError == 'contracts') {
                    typeLabel = 'Контракт';
                    const errorsByLine = errors.filter(error => error.error?.contract?.id == e.id);
                    const res: Line = {
                        id: e.line!.cns_id,
                        title: e.line!.cns_title ?? '',
                        ed_izm: e.line!.cns_ed_izm,
                        size: e.size,
                        price: e.price,
                        sum: e.sum_wat,
                        doc_id: e.id,
                        doc_id2: e.document_id,
                        doc_name: `Документы для контракта ${e.doc_number} от ${dateFormat(e.doc_date)}.zip`,
                        hasErrorSize: !!errorsByLine.find(error => error.error?.fields?.includes(`${preficsErrorField}volume`)),
                        hasErrorPrice: !!errorsByLine.find(error => error.error?.fields?.includes(`${preficsErrorField}price`)),
                        hasErrorSum: !!errorsByLine.find(error => error.error?.fields?.includes(`${preficsErrorField}sum`)),
                    };
                    return res;
                }

                if (props.typeError == 'invoices') {
                    typeLabel = 'Счет';
                    const errorsByLine = errors.filter(error => error.error?.payment_invoice?.id == e.id);
                    return {
                        id: e.line!.cns_id,
                        title: props.nomenclatures[index]?.cns_title ?? '',
                        ed_izm: props.nomenclatures[index]?.cns_ed_izm ?? '',
                        size: `${e.line!.cns_invoice_size}`,
                        price: `${e.line!.cns_invoice_price}`,
                        sum: `${e.line!.cns_invoice_sum}`,
                        doc_id: e.id,
                        doc_id2: e.document_id,
                        doc_name: `Документы для счета ${e.doc_number} от ${dateFormat(e.doc_date)}.zip`,
                        hasErrorSize: !!errorsByLine.find(error => error.error?.fields?.includes(`${preficsErrorField}volume`)),
                        hasErrorPrice: !!errorsByLine.find(error => error.error?.fields?.includes(`${preficsErrorField}price`)),
                        hasErrorSum: !!errorsByLine.find(error => error.error?.fields?.includes(`${preficsErrorField}sum`)),
                    };
                }

                if (props.typeError == 'way_bills') {
                    typeLabel = 'ТТН';
                    const errorsByLine = errors.filter(error => error.error?.waybill?.id == e.id);
                    return {
                        id: e.line!.cns_id,
                        title: props.nomenclatures[index]?.cns_title ?? '',
                        ed_izm: props.nomenclatures[index]?.cns_ed_izm ?? '',
                        size: `${e.line!.cns_well_bill_size}`,
                        price: `${e.line!.cns_well_bill_delivery_sum / Number(e.line!.cns_well_bill_size)}`,
                        sum: `${e.line!.cns_well_bill_delivery_sum}`,
                        doc_id: e.id,
                        doc_id2: e.document_id,
                        doc_name: `Документы для ттн ${e.doc_number} от ${dateFormat(e.doc_date)}.zip`,
                        hasErrorSum: !!errorsByLine.find(error => error.error?.fields?.includes(`${preficsErrorField}sum`)),
                        hasErrorPrice: !!errorsByLine.find(error => error.error?.fields?.includes(`${preficsErrorField}price`)),
                        hasErrorSize: !!errorsByLine.find(error => error.error?.fields?.includes(`${preficsErrorField}volume`)),
                    };
                }

                if (props.typeError == 'bills') {
                    typeLabel = 'Счет-фактура';
                    return {
                        id: e.line!.cns_id,
                        title: props.nomenclatures[index]?.cns_title ?? '',
                        ed_izm: props.nomenclatures[index]?.cns_ed_izm ?? '',
                        size: `${e.line!.cns_bill_size}`,
                        price: `${e.line!.cns_bill_price}`,
                        sum: `${e.line!.cns_bill_sum}`,
                        doc_id: e.id,
                        doc_id2: e.document_id,
                        doc_name: `Документы для счет-фактуры ${e.doc_number} от ${dateFormat(e.doc_date)}.zip`,
                        hasErrorSize: !!errors.find(error => error.error?.fields?.includes(`${preficsErrorField}volume`)),
                        hasErrorPrice: !!errors.find(error => error.error?.fields?.includes(`${preficsErrorField}price`)),
                        hasErrorSum: !!errors.find(error => error.error?.fields?.includes(`${preficsErrorField}sum`)),
                    };
                }

                return { id: '', title: '', size: '', price: '', sum: '', ed_izm: '', doc_name: '', doc_id: '', doc_id2: '', hasErrorSize: false, hasErrorPrice: false, hasErrorSum: false };
            })
            .filter(e => e.id && Number(e.size));

        const countErrors = props.hasErrorSizeWithDates ? 2 : 1; //props.errors.filter(e => !e.closed_at).length;
        const countErrorsClosed = 0; // props.errors.filter(e => !!e.closed_at).length;

        const isClosed = !!errors.find(error => error.closed_at);

        return <>
            <div key={`modal-error-view__error_title_${props.contractId}`}>
                {/*<span className={'modal-error-view__error_title'}>{contract.contragent}</span>*/}
                {/*{!!countErrors && <span*/}
                {/*    className={'modal-error-view__error_count'}>*/}
                {/*        {countErrors}*/}
                {/*    </span>}*/}
                {!!countErrorsClosed && <span
                    className={'modal-error-view__error_count closed'}>
                        {countErrorsClosed}
                    </span>}
            </div>
            <div className={'modal-error-view__error_contract'}
                 key={`modal-error-view__error_contract_${contract.id}`}
            >
                <div
                    className={'modal-error-view__error_contract_title'}>
                    <span>{typeLabel}, №{contract.doc_number} </span>
                    <span>от {dateFormatter(contract.doc_date ?? '')}</span>
                </div>
                {/*<span>{contract.size} {contract.line!.cns_ed_izm}</span>*/}
                {/*<span> × {numberFormatter(contract.price)}</span>*/}
                {/*<span>   = {numberFormatter(contract.sum_wat)} </span>*/}
            </div>
            <div key={`modal-error-view__error_contract_lines_${contract.id}`}>
                {lines.map(line => {
                    return <>
                        <div
                            className={`modal-error-view__error_contract_line  ${isClosed ? 'closed' : ''}`}
                            key={`modal-error-view__error_contract_line_${line.id}`}
                        >
                            <div
                                key={`modal-error-view__error_contract_line_title_${line.id}`}> {line.title} </div>
                            <span className={line.hasErrorSize ? 'has_error' : ''}
                                  key={`modal-error-view__error_contract_line_size_${line.id}`}
                            >{numberFormatter(line.size, { isTwoDigit: true })} {line.ed_izm}</span>
                            <span className={line.hasErrorPrice ? 'has_error' : ''}
                                  key={`modal-error-view__error_contract_line_price_${line.id}`}
                            >× {numberFormatter(line.price, { isTwoDigit: true })} ₽</span>
                            <span className={line.hasErrorSum ? 'has_error' : ''}
                                  key={`modal-error-view__error_contract_line_sum_${line.id}`}
                            >= {numberFormatter(line.sum, { isTwoDigit: true })} ₽</span>
                        </div>
                    </>;
                })}
                {props.typeError == TypeModalEnum.contracts && <>
                    <DownloadFile
                        isBtn={true}
                        file={`${hostEpo}/api/passport/${docType}/${lines[0]?.doc_id}/files-archive?file_type=1`}
                        title={'Скачать прототип'}
                        name={lines[0]?.doc_name}
                    /> <br />
                    { contract.status == 'signed' && <DownloadFile
                        isBtn={true}
                        file={`${hostEpo}/api/${docType}/documents/${lines[0]?.doc_id2}/files-archive?file_type=2`}
                        title={'Скачать подписанный контракт'}
                        name={lines[0]?.doc_name}
                    />}
                </>}
                {props.typeError != TypeModalEnum.contracts && <DownloadFile
                    isBtn={true}
                    file={`${hostEpo}/api/passport/${docType}/${lines[0]?.doc_id}/files-archive`}
                    title={'Скачать документы'}
                    name={lines[0]?.doc_name}
                />}
            </div>
        </>;
    }

    return <div key={`modal-error-view__error_${props.contractId}`}></div>;

};

export default ModalErrorLines;
