import { max, uniq } from 'lodash';

export interface NomenclatureListModalPositionState {
  entity_id: string,
  title: string,
  unit: string,
  place: {
    value: string[],
    section_id: string[],
    work_type_id: string[],
    nomenclature_id: string,
  }
}

export interface NomenclatureListModalPositionStatePlace {
  value: string[],
  section_id: string[],
  work_type_id: string[],
  nomenclature_id: string,
}

export function getEmptyNomenclatureListModalPositionState(): NomenclatureListModalPositionState {
  return {
    entity_id: '',
    title: '',
    unit: '',
    place: {
      value: [],
      section_id: [],
      work_type_id: [],
      nomenclature_id: '',
    },
  };
}

export interface TreeNode {
  title: string,
  value: string,
  disabled: boolean,
  children: {
    title: string,
    value: string,
    disabled: boolean,
  }[],
}

export function getEntityType(section_id?: string, type_id?: string, nomenclature_id?: string) {
  if (nomenclature_id) {
    return 'nomenclature';
  } else if (type_id) {
    return 'work_type';
  } else if (section_id) {
    return 'section';
  }

  return 'nomenclature';
}

export function getEntityTitle(type: 'section' | 'work_type' | 'nomenclature') {
  switch (type) {
    case 'section':
      return 'Раздел';
    case 'work_type':
      return 'Вид работ';
    case 'nomenclature':
      return 'Номенклатура';
  }
}

export interface LocalValueNomenclature {
  title: string,
  unit: string,
  prices: {region_id: string, price: number}[]
}

function getAllowRows() {
  const allInputs = [...document.querySelectorAll("input[type='text']") as any].map(e => Number(e.attributes?.['data-row-index']?.nodeValue ?? 0)).filter(e => e);
  allInputs.sort((a, b) => a - b);
  return uniq(allInputs)
}
function getAllowColumns() {
  const allInputs = [1, ...[...document.querySelectorAll("input[type='text']") as any].map(e => Number(e?.attributes?.['data-column-index']?.nodeValue ?? 0)).filter(e => e)];
  allInputs.sort((a, b) => a - b);
  return uniq(allInputs)
}

function getIndexRow(e: HTMLInputElement) {
  if ((e as any).attributes?.['data-row-index']) {
    return Number((e as any).attributes?.['data-row-index']?.nodeValue ?? -1);
  }

  return Number((e as any)?.parentElement?.parentElement?.parentElement?.attributes?.['data-row-index']?.nodeValue ?? -1);
}

function getIndexColumn(e: HTMLInputElement) {
  if ((e as any).attributes?.['data-column-index']) {
    return Number((e as any).attributes?.['data-column-index']?.nodeValue ?? -1)
  }

  return Number((e as any)?.parentElement?.parentElement?.parentElement?.attributes?.['data-column-index']?.nodeValue ?? -1);
}

export function doRightInput(e: KeyboardEvent) {
  const columns = getAllowColumns();
  const indexRow = getIndexRow(e.target as any);
  const indexColumn = getIndexColumn(e.target as any);

  if (indexColumn > -1 && (e.target as any).selectionEnd == (e.target as any).value?.length) {
    if (indexColumn != Math.max(...columns)) {
      (document.querySelector(`input[data-column-index="${indexColumn + 1}"][data-row-index="${indexRow}"]`) as any)?.focus();
    }
  }
}

export function doLeftInput(e: KeyboardEvent) {
  const columns = getAllowColumns();
  const indexRow = getIndexRow(e.target as any);
  const indexColumn = getIndexColumn(e.target as any);

  if (indexColumn > -1 && (e.target as any).selectionStart == 0) {
    if (indexColumn != Math.min(...columns)) {
      if (indexColumn == 2) {
        (document.querySelector(`[data-column-index="${indexColumn - 1}"][data-row-index="${indexRow}"] input`) as any)?.focus();
      } else {
        (document.querySelector(`input[data-column-index="${indexColumn - 1}"][data-row-index="${indexRow}"]`) as any)?.focus();
      }
    }
  }

  const tableScroll = document.querySelector(".ant-table-body");
  if (tableScroll) {
    const scrollLeft = tableScroll.scrollLeft ?? 0;

    const maxScrollLeft = (indexColumn - 3) * 120;

    if (scrollLeft > maxScrollLeft) {
      tableScroll.scrollLeft = maxScrollLeft;
    }
  }
}

export function doUpInput(e: KeyboardEvent) {
  const rows = getAllowRows();
  const indexRow = getIndexRow(e.target as any);
  const indexColumn = getIndexColumn(e.target as any);

  if (indexRow > -1) {
    if (indexRow != Math.min(...rows)) {
      (document.querySelector(`input[data-column-index="${indexColumn}"][data-row-index="${indexRow - 1}"]`) as any)?.focus();
    }
  }
}

export function doDownInput(e: KeyboardEvent) {
  const rows = getAllowRows();
  const indexRow = getIndexRow(e.target as any);
  const indexColumn = getIndexColumn(e.target as any);

  if (indexRow > -1) {
    if (indexRow != Math.max(...rows)) {
      (document.querySelector(`input[data-column-index="${indexColumn}"][data-row-index="${indexRow + 1}"]`) as any)?.focus();
    }
  }
}
