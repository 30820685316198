import React, { FunctionComponent, useMemo, useState } from 'react';
import { Button, Input, notification } from 'antd';
import { getWData } from '../../../table_extends/getWData';
import { TableRecordContract } from '../TableRecord';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { localeKeys } from '../../../../i18n/localeKeys';
import { useTranslation } from 'react-i18next';
import { documentsApi } from '../../../../shared/api_client';
import moment from 'moment';
import { ContractGroupedListItemApproval } from '../../../../ApiEpo';
import { getPassportCache } from "../../../table_extends/loadData";
import { useUser } from "../../../../actions/useUser";
import { tokenPayload } from "../../../../actions/tokenActions";
import { delay } from "../../../../shared/delay";

function getDeltaTimeAction( action: ContractGroupedListItemApproval ) {
    let deltaTimeMinutes = moment( action.moved_at ).diff( moment( action.created_at ), 'minute' );
    let deltaTimeHours = moment( action.moved_at ).diff( moment( action.created_at ), 'hour' );
    let deltaTime = `${ deltaTimeHours } ч. ${ deltaTimeMinutes - deltaTimeHours * 60 } мин.`;

    if ( deltaTimeHours < 1 ) {
        deltaTime = `${ deltaTimeMinutes } мин.`;
    }

    return deltaTime;
}

type typeAction = 'approve' | 'reject' | 'sing' | 'approve_spm' | 'approve_hd' | 'signing';
const ModalErrorContractAgreement: FunctionComponent<{
    docId: string,
    contractLines: TableRecordContract[],
    onAction: ( type: typeAction, file_ids?: string[] ) => Promise<void>,
    showHistory: boolean,
}> = ( props ) => {
    const [ loading, setLoading ] = useState<'none' | 'loadingApprove' | 'loadingReject' | 'loadingFile'>( 'none' );
    const { t } = useTranslation();

    async function uploadFile( event: any ) {
        const filesUpd: { id: string, name: string }[] = [];

        try {
            for ( let file of event.target.files ) {
                console.log( file, process.env.REACT_APP_DEBUG_SYNC_IS_ONLINE );
                const res = await documentsApi.files.uploadCreate( { file: file } );
                console.log( res );
                if ( (res.data as any).error_epo_be ) {
                    notification.error( { message: t( localeKeys.files.uploadError ) } );
                } else {
                    const itemUploaded = { id: res.data.id ?? '', name: res.data.name ?? '-' };
                    filesUpd.push( itemUploaded );

                    await new Promise( ( resolve ) => setTimeout( resolve, 100 ) );
                }
            }
        } catch ( e ) {
            console.error( e );
            notification.error( { message: t( localeKeys.files.uploadError ) } );
        }

        return filesUpd;
    }


    const contractAgreement = useMemo( () => {
        return getWData().db.contractsAgreement?.find( e => e.id == props.docId );
    }, [ props.docId, props.contractLines, loading ] );

    (window as any).modalErrorContractAgreement = {
        props,
        contractAgreement,
        loading,
    };

    const btnCancel = () => <>
        <Button type={ 'link' } size={ 'small' } data-type={'BTN_AGREEMENT_REJECT'}
                className={ 'modal-error-confirm__cancel' }
                onClick={ async () => {
                    setLoading( 'loadingReject' );
                    await props.onAction( 'reject' );
                    setLoading( 'none' );
                } }                disabled={ loading != 'none' }
                loading={ loading == 'loadingReject' }
        >
            <img src={ '/canvas_table/icon_warning3.svg' } alt={ 'Warning' }/>
            Отклонить
        </Button>
    </>

    const btnApprove = ( title: string = 'Согласовать', action: typeAction, allowAction: boolean ) => allowAction ?
        <>
            <Button type={ 'link' } size={ 'small' }
                    data-type={'BTN_AGREEMENT_APPROVE'}
                    className={ 'modal-error-confirm__approve' }
                    onClick={ async () => {
                        setLoading( 'loadingApprove' );
                        await props.onAction( action );
                        setLoading( 'none' );
                    } }
                    disabled={ loading != 'none' }
                    loading={ loading == 'loadingApprove' || loading == 'loadingFile' }
            >
                <img src={ '/canvas_table/icon_warning4.svg' } alt={ 'Warning' }/>
                { title }
            </Button>
        </> : null

    const lastAction = useMemo( () => {
        const actionSPM = contractAgreement?.approvals?.find( e => e.status == 'on_approval_by_spm' );
        const actionDk = contractAgreement?.approvals?.find( e => e.status == 'on_approval_by_dk' );
        const actionDk2 = (contractAgreement?.approvals?.length ?? 0) > 2 ? contractAgreement?.approvals?.reverse()?.find( e => e.status == 'on_approval_by_dk' ) : undefined;
        const actionSigning = contractAgreement?.approvals?.find( e => e.status == 'signing' );

        if ( actionSigning && actionSigning.moved_at && props.showHistory ) {
            return <>
                Контракт подписан { actionDk2?.user_fullname } ({ actionSigning.user_position })
                через { getDeltaTimeAction( actionSigning ) }
            </>;
        }

        if ( actionDk2 && actionDk2.moved_at && props.showHistory ) {
            return <>
                Контракт согласован { actionDk2?.user_fullname } ({ actionDk2.user_position })
                через { getDeltaTimeAction( actionDk2 ) }
            </>;
        }

        if ( actionDk && actionDk.moved_at && props.showHistory ) {
            return <>
                Согласовал { actionDk?.user_fullname } ({ actionDk.user_position })
                через { getDeltaTimeAction( actionDk ) }
            </>;
        }

        if ( actionSPM && actionSPM.moved_at && props.showHistory ) {
            return <>
                Согласовал { actionSPM?.user_fullname } ({ actionSPM.user_position })
                через { getDeltaTimeAction( actionSPM ) }
            </>;
        }


        return null;
    }, [ contractAgreement ] );

    const { userInfo } = useUser()

    const userIsHd = tokenPayload()?.groups?.includes( 'data_keeper' );
    const userIsRp = !!getPassportCache().members?.find( user => user.position == 'pm' && user.user_id == userInfo?.data?.id ) || userIsHd
    const userIsSrp = !!getPassportCache().members?.find( user => user.position == 'senior_pm' && user.user_id == userInfo?.data?.id ) || userIsHd

    let action = null;

    if ( contractAgreement?.status == 'on_work' ) {
        action = <>
            <span><img src={'/canvas_table/icon_warning3.svg'} alt={'Warning'}/> Согласование контракта</span>
            <span>
                { btnApprove( 'Согласовать', 'approve', userIsRp ) }
                { btnCancel() }
            </span>
        </>;
    }

    if ( contractAgreement?.status == 'on_approval_by_dk' ) {
        action = <>
            <span>Согласование контракта ХД</span>
            <span>
                { btnApprove( 'Согласовать', 'approve_hd', userIsHd ) }
                { btnCancel() }
            </span>
        </>;
    }

    if ( contractAgreement?.status == 'signing' ) {
        action = <>
            <span>Подписание контракта</span>
            { (userIsRp || userIsSrp || userIsHd) && <span className={ 'upload_file' }>
                <div className={ `wrap_file no-bg ${ loading == 'loadingApprove' ? 'disabled' : '' }` }
                     style={ { width: 270 } }>
                    <span className={ 'wrap_file__title' }>
                        { (loading == 'loadingFile' || loading == 'loadingApprove') && <LoadingOutlined/> }
                        { (loading != 'loadingFile' && loading != 'loadingApprove') && <UploadOutlined/> }
                        &nbsp;Загрузить подписанный документ
                    </span>
                    <Input
                        type="file"
                        className={ 'file contragent_file_many' }
                        disabled={ loading == 'loadingFile' || loading == 'loadingApprove' }
                        onChange={ async ( e ) => {
                            try {
                                setLoading( 'loadingFile' )
                                const files = await uploadFile( e );

                                if ( files?.length ) {
                                    await props.onAction( 'signing', [ ...files.map( e => e.id ) ] );
                                    // await delay(10000)
                                }
                            } catch ( e ) {
                                console.error( e );
                            }
                            setLoading( 'none' )
                        }}
                    />
                </div>
            </span> }
            { !(userIsRp || userIsSrp || userIsHd) && <>
                Нет прав для загрузки файла
            </> }
        </>;
    }

    if ( contractAgreement?.status == 'signed' ) {
        action = <>
            <span>Контракт подписан</span>
        </>;
    }

    if ( contractAgreement?.status == 'on_approval_by_spm' ) {
        action = <>
            <span>Согласование контракта СРП</span>
            <span>
                { btnApprove( 'Согласовать', 'approve_spm', userIsSrp ) }
                { btnCancel() }
            </span>
        </>;
    }

    if ( contractAgreement?.status == 'approval_dk' && props.showHistory ) {
        action = <>
            <span>Контракт согласован неким "ДК"</span>
        </>;
    }

    if ( contractAgreement?.status == 'rejected' && props.showHistory ) {
        action = <>
            <span>Контракт отклонен</span>
        </>;
    }

    return <>
        { lastAction ? <div className={ 'modal-error-confirm closed' }>
            { lastAction }
        </div> : null }
        { contractAgreement && action && <div className={ 'modal-error-contract-agreement' }>
            { action }
        </div> }
    </>;
};

export default ModalErrorContractAgreement;
