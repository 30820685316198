import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { getWData } from '../../../table_extends/getWData';
import { Modal, notification } from 'antd';
import { ProjectError } from '../../../../ApiEpo';
import dateFormat, { dateFormatter, numberFormatter } from '../../../../shared/dateFormat';
import {
    addEvent2Bus,
    getLoadDataInfo,
    getPassportCache, getUrlPassportId,
    loadData,
    removeEventBus,
} from '../../../table_extends/loadData';
import ModalErrorConfirm from './modal_error_confirm';
import {
    getNamePropsWithData,
    getTypeModalEnum,
    mapContragentLine,
    TypeModalEnum,
} from '../utils/getErrorFieldPrefics';
import { getEmptyTableRecord, TableRecord, TableRecordContract } from '../TableRecord';
import { ErrorTypeEnum } from '../utils/error_type';
import ModalErrorLines from './modal_error_lines';
import { localeKeys } from '../../../../i18n/localeKeys';
import { useTranslation } from 'react-i18next';
import ModalErrorContractAgreement from './modal_error_contract_agreement';
import { api } from '../../../../shared/api_client';
import { delay } from '../../../../shared/delay';
import { useContractsAgreement } from '../../../../actions/useContractsAgreement';
import { useContractsStatuses } from '../../../../actions/useContractsStatuses';
import tokenActions, { AccessItem } from "../../../../actions/tokenActions";


const ModalErrorView: FunctionComponent<{
    isOpen: boolean,
    open: boolean | string,
    onOk: () => void,
    onCancel: () => void,
    onAction: (type: 'confirm' | 'cancel') => void,
    errors: ProjectError[],
    typeError: TypeModalEnum,
    isAgreementContract: boolean
}> = (props) => {
    const {t} = useTranslation();
    const [refresh, setRefresh] = useState(0);
    const loadDataLocal = useMemo(() => getLoadDataInfo(), [props.isOpen, refresh]);
    const {clearCacheContractsAgreement, isLoadingContractsAgreement} = useContractsAgreement();
    const {clearCacheContractsStatuses} = useContractsStatuses();

    const namePropsWithData = getNamePropsWithData(props.typeError);

    const [rows3, setRows3] = useState<TableRecord[]>([]);

    useEffect(() => {
        if (!props.isOpen) return;
        setRows3(getLoadDataInfo().rowsTable.rows3);
    }, [props.isOpen]);

    const documentLines: TableRecordContract[] = useMemo(() => {
        if (typeof props.open == 'string') {
            if (props.typeError == TypeModalEnum.contracts) {
                const firstRowWithDocument = rows3.find(e => e.cns_contracts.find(e2 => e2.id == props.open));
                const docId = firstRowWithDocument?.cns_contracts?.find(e => e.id == props.open)?.document_id ?? '';
                return rows3.map(e => mapContragentLine(e, e.cns_contracts.filter(e2 => e2.document_id == docId))).flat();
            }

            const id = props.open.toString().split('_').pop() ?? '';
            const firstRowWithDocument = rows3.find(e => e[namePropsWithData].find(e2 => e2.id == id));
            const docId = firstRowWithDocument?.[namePropsWithData]?.find(e => e.id == id)?.document_id ?? '';


            return getWData().rows3.map(e => mapContragentLine(e, e[namePropsWithData].filter(e2 => e2.document_id == docId))).flat();
        } else {
            switch (props.typeError) {
                case TypeModalEnum.contracts:
                    const docContractId = props.errors[0]?.error?.contract?.id ?? '';
                    const docContractIds = rows3.map(e => e.cns_contracts).flat().filter(e => e.id == docContractId).map(e => e.document_id);
                    return rows3.map(e => mapContragentLine(e, e.cns_contracts.filter(e2 => docContractIds?.includes(e2.document_id)))).flat();
                case TypeModalEnum.invoices:
                    const docInvoiceId = props.errors[0]?.error?.payment_invoice?.id ?? '';
                    const docInvoiceIds = rows3.map(e => e.cns_invoices).flat().filter(e => e.id == docInvoiceId).map(e => e.document_id);
                    return rows3.map(e => mapContragentLine(e, e.cns_invoices.filter(e2 =>  docInvoiceIds?.includes(e2.document_id)))).flat();
                case TypeModalEnum.way_bills:
                    const docWaybillId = props.errors[0]?.error?.waybill?.id ?? '';
                    const docWaybillIds = rows3.map(e => e.cns_way_bills).flat().filter(e => e.id == docWaybillId).map(e => e.document_id);
                    return rows3.map(e => mapContragentLine(e, e.cns_way_bills.filter(e2 => docWaybillIds?.includes(e2.document_id)))).flat();
                case TypeModalEnum.bills:
                    const docBillId = props.errors[0]?.error?.bill?.id ?? '';
                    const docBillIds = rows3.map(e => e.cns_bills).flat().filter(e => e.id == docBillId).map(e => e.document_id);
                    return rows3.map(e => mapContragentLine(e, e.cns_bills.filter(e2 => docBillIds?.includes(e2.document_id)))).flat();
                default:
                    return [];
            }
        }
    }, [props.open, rows3, props.typeError, isLoadingContractsAgreement]);

    const documentId = useMemo(() => {
        if (typeof props.open == 'string') {
            if (props.typeError == TypeModalEnum.contracts) {
                return props.open;
            }

            return documentLines?.[0]?.id ?? '';
        }

        if (props.typeError == TypeModalEnum.contracts) {
            return props.errors[0]?.error?.contract?.id ?? '';
        }

        if (props.typeError == TypeModalEnum.invoices) {
            return props.errors[0]?.error?.payment_invoice?.id ?? '';
        }

        if (props.typeError == TypeModalEnum.way_bills) {
            return props.errors[0]?.error?.waybill?.id ?? '';
        }

        if (props.typeError == TypeModalEnum.bills) {
            return props.errors[0]?.error?.bill?.id ?? '';
        }

        return '';
    }, [props.open]);

    const document2Id = documentLines?.[0]?.document_id ?? '';

    const contractFirstLine = useMemo(() => {
        if (typeof props.open == 'string') {
            if (props.typeError == TypeModalEnum.contracts) {
                return documentLines.find(e => e.id == props.open);
            }
            return documentLines.find(e => e.id == (props.open).toString().split('_').pop());
        }
        return documentLines[0];
    }, [documentLines, isLoadingContractsAgreement]);

    const contractDocument = useMemo(() => {
        if (props.typeError == TypeModalEnum.contracts) {
            return contractFirstLine;
        }

        const contractId = contractFirstLine?.parent_contract_id ?? '';

        const contractTableRecord = rows3.find(e => e.cns_contracts.find(e => e.document_id == contractId));

        return contractTableRecord?.cns_contracts?.find(e => e.document_id == contractId);
    }, [props.errors, props.open, rows3, isLoadingContractsAgreement]);

    const contractsLines: TableRecordContract[] = useMemo(() => {
        if (props.typeError == 'contracts') return [];

        const contracts = rows3.filter(e => e.cns_contracts.find(e2 => e2.document_id == contractDocument?.document_id));

        return contracts
            .map(e => e.cns_contracts.filter(e2 => e2.document_id == contractDocument?.document_id).map(e3 => ({
                ...e3,
                line: e
            })))
            .filter(e => e)
            .flat()
            .reverse()
    }, [documentLines, contractDocument, isLoadingContractsAgreement]);


    const nomenclatures: TableRecord[] = useMemo(() => {
        if (document2Id) {
            return rows3.filter(row3 => row3[namePropsWithData].find(e3 => e3.document_id == document2Id));
        }

        return documentLines.map(e => {
            return rows3.find(e2 => e2[namePropsWithData].find(e3 => e3.id == e.id)) ?? getEmptyTableRecord();
        });
    }, [props.errors, props.open, rows3, isLoadingContractsAgreement]);

    const nomenclatureByGroups: TableRecord[] = useMemo(() => {
        const groupUniqIds = new Set(nomenclatures.map(e => e.cns_group_id));
        return Array.from(groupUniqIds).map(groupId => {
            return nomenclatures.find(e => e.cns_group_id == groupId) ?? getEmptyTableRecord();
        });
    }, [nomenclatures]);

    const errors: ProjectError[] = useMemo(() => {
        if (documentId) {
            const allErrors = getLoadDataInfo()?.errors ?? [];

            if (props.typeError == TypeModalEnum.contracts) {
                const contractIds = documentLines?.map(e => e.id);
                return allErrors.filter( e => contractIds?.includes(e.error?.contract?.id ?? '') && e.type?.includes( 'contract' ) );
            }

            if (props.typeError == TypeModalEnum.invoices) {
                const invoiceIds = documentLines?.map(e => e.id);
                return allErrors.filter( e => invoiceIds?.includes(e.error?.payment_invoice?.id ?? '') && e.type?.includes( 'invoice' ) );
            }

            if (props.typeError == TypeModalEnum.way_bills) {
                const waybillIds = documentLines?.map(e => e.id);
                return allErrors.filter( e => waybillIds?.includes(e.error?.waybill?.id ?? '') && e.type?.includes( 'waybill' ) );
            }

            if (props.typeError == TypeModalEnum.bills) {
                const billIds = documentLines?.map(e => e.id);
                return allErrors.filter( e => billIds?.includes(e.error?.bill?.id ?? '') && e.type?.includes( 'bill' ) );
            }
        }

        if (props.errors.length) return props.errors;

        return [];
    }, [props.errors, props.typeError, documentLines, getLoadDataInfo()?.errors, isLoadingContractsAgreement]);


    useEffect(() => {
        // console.log('ModalErrorView', props.errors);

        addEvent2Bus('updated-errors', setRefresh, 'modal-error-view')

        return () => {
            removeEventBus('updated-errors', 'modal-error-view')
        };
    }, [props.errors]);

    const isErrorWithCompleted = useMemo(() => {
        return errors.find(e => e.type == ErrorTypeEnum.CONTRACT_NOT_COMPLETED_ERROR);
    }, [errors, isLoadingContractsAgreement]);

    const isErrorWithPlaneDates = useMemo(() => {
        return errors.find(e => e.type == ErrorTypeEnum.CONTRACT_PLANNING_DATE_ERROR);
    }, [errors, isLoadingContractsAgreement]);

    const isErrorWithDates = useMemo(() => {
        return errors.find(e => e.type == ErrorTypeEnum.CONTRACT_WORK_START_DATE_ERROR || e.type == ErrorTypeEnum.CONTRACT_WORK_END_DATE_ERROR);
    }, [errors, isLoadingContractsAgreement]);

    const isErrorSize = useMemo(() => {
        return errors.find(e => [ErrorTypeEnum.CONTRACT_OVER_ERROR, ErrorTypeEnum.INVOICE_OVER_ERROR, ErrorTypeEnum.WAYBILL_OVER_ERROR, ErrorTypeEnum.BILL_OVER_ERROR].includes(e.type as ErrorTypeEnum));
    }, [errors, isLoadingContractsAgreement]);


    (window as any).modalErrorView = {
        props,
        isErrorWithDates,
        isErrorWithCompleted,
        isErrorSize,
        documentId,
        documentLines,
        contractFirstLine,
        nomenclatures,
        errors,
        contractDocument,
        document2Id,
        contractsLines,
        rows3,
        namePropsWithData,
    };

    return <Modal
        title={contractFirstLine ? `${contractFirstLine.contragent} №${contractFirstLine.doc_number} от ${dateFormat(contractFirstLine.doc_date)}` : loadDataLocal.passport?.title ?? 'Ошибка'}
        open={!!props.open}
        onCancel={() => props.onCancel()}
        destroyOnClose={true}
        footer={<></>}
        className="modal-error-view"
    >
        {document2Id == '' && props.typeError == TypeModalEnum.contracts && tokenActions.contracts == AccessItem.NONE && <>
            Не хватает прав для просмотра раздела <br/> <br/>
        </>}
        {document2Id == '' && props.typeError == TypeModalEnum.invoices && tokenActions.invoices == AccessItem.NONE && <>
            Не хватает прав для просмотра раздела <br/> <br/>
        </>}
        {document2Id == '' && props.typeError == TypeModalEnum.way_bills && tokenActions.wellBill == AccessItem.NONE && <>
            Не хватает прав для просмотра раздела <br/> <br/>
        </>}
        {document2Id == '' && props.typeError == TypeModalEnum.bills && tokenActions.bill == AccessItem.NONE && <>
            Не хватает прав для просмотра раздела <br/> <br/>
        </>}

        {contractFirstLine?.line && <div className={'modal-error-view__subtitle2'}>
            <div style={{
                fontSize: '16px',
                fontWeight: 600,
            }}>{t(localeKeys.passportListScreen.table.title)}: {loadDataLocal.passport.title}</div>
            {props.typeError == TypeModalEnum.contracts && <>
                <div>
                    <span>{t(localeKeys.table.columns.contractsDateWorkStart)}:</span> {dateFormatter(contractFirstLine.date_start)}
                </div>
                <div>
                    <span>{t(localeKeys.table.columns.contractsDateWorkEnd)}:</span> {dateFormatter(contractFirstLine.date_end)}
                </div>
            </>}
            <div className={'modal-error-view__delimiter'}></div>
        </div>}

        {props.typeError == TypeModalEnum.contracts && <>
            <div className={'modal-error-view__title'}>Бюджет-план</div>
            {nomenclatureByGroups.map(row => {
                const rowSection = getWData().rows.find(e => e.cns_section_id === row.cns_section_id);
                const rowWorkType = getWData().rows2.find(e => e.cns_group_id === row.cns_group_id);

                return <>
                    {rowSection && rowWorkType && <div
                        className={'modal-error-view__subtitle'}>{rowSection?.cns_title} / {rowWorkType?.cns_title} &nbsp; </div>}
                    {nomenclatures.filter(row2 => row2.cns_group_id == row.cns_group_id).map((row2, index) => {
                        return <>
                            <div className={'modal-error-view__bp_line'} key={`modal-error-view__${row.cns_id}`}>
                                <span>{row2.cns_title} &nbsp;</span>
                                <span>{numberFormatter(row2.cns_budget_plan_size)} {row2.cns_ed_izm}</span>
                                <span>× {numberFormatter(row2.cns_budget_plan_price)} ₽</span>
                                <span> = {numberFormatter(row2.cns_budget_plan_sum_wat)} ₽</span>
                            </div>
                        </>;
                    })}
                </>;
            })}
        </>}
        {contractDocument && props.typeError != TypeModalEnum.contracts && <>
            <div className={'modal-error-view__title'}>По контракту
                № {contractDocument?.doc_number} от {dateFormat(contractDocument?.doc_date)} </div>
            {nomenclatureByGroups.map(row => {
                const rowSection = getWData().rows.find(e => e.cns_section_id === row.cns_section_id);
                const rowWorkType = getWData().rows2.find(e => e.cns_group_id === row.cns_group_id);

                return <>
                    {rowSection && rowWorkType && <div
                        className={'modal-error-view__subtitle'}>{rowSection.cns_title} / {rowWorkType.cns_title} &nbsp; </div>}
                    {[...contractsLines].reverse()
                        // .filter(row2 => row2.cns_group_id == row.cns_group_id)
                        .map((row, index) => {
                            const row2 = nomenclatures.find(e => e.cns_nomenclature_id == row.line?.cns_nomenclature_id);
                            return <>
                                {row2 && <div className={'modal-error-view__bp_line'}
                                              key={`modal-error-view__${row2.cns_id}`}>
                                    <span>{row2.cns_title} &nbsp;</span>
                                    <span>{numberFormatter(row.size)} {row2.cns_ed_izm}</span>
                                    <span>× {numberFormatter(row.price)} ₽</span>
                                    <span> = {numberFormatter(row.sum_wat)} ₽</span>
                                </div>}
                            </>;
                        })}
                </>;

            })}
        </>}


        <div className={'modal-error-view__delimiter'}></div>

        {isErrorWithCompleted && <>
            <div className={' modal-error-view__desc'}>Дата окончания работ по контракту:
                <span>{dateFormatter(isErrorWithCompleted.error?.contract_data?.work_end_date ?? '')}</span>
            </div>
            <div className={' modal-error-view__desc'}>Выполнение на объекте:
                <span>{numberFormatter(isErrorWithCompleted.error?.delivered_completed?.completed_percent ?? 0, {isTwoDigit: true}) ?? 0}%</span>
            </div>
        </>}

        {isErrorWithPlaneDates && <>
            <div className={' modal-error-view__desc'}>
                Плановая дата заключения
                контракта {dateFormatter(isErrorWithPlaneDates.error?.contract_data?.planning_date ?? '')}
            </div>
        </>}

        {/*<div className={'modal-error-view__title'}>Контракты</div>*/}
        <ModalErrorLines
            contractId={documentId}
            contractLines={documentLines}
            nomenclatures={nomenclatures}
            errors={[isErrorSize, isErrorWithDates, ...errors].filter(e => !!e) as ProjectError[]}
            hasErrorSizeWithDates={!!isErrorSize && !!isErrorWithDates}
            typeError={props.typeError}
        />


        {isErrorWithPlaneDates && <>
            {/*Ошибка с плановыми датами, не знаю как показать ее (нет контракта)*/}
        </>}

        {isErrorWithCompleted && <>

            {/*Ошибка с неполным объемом контраков*/}
        </>}

        {!isErrorWithCompleted && !isErrorSize && isErrorWithDates && <>
            <ModalErrorConfirm error={isErrorWithDates} hasErrorSizeWithDates={false} onAction={props.onAction}/>

            {/*Ошибка с датами*/}
        </>}

        {!isErrorWithCompleted && isErrorSize && isErrorWithDates && <>
            <ModalErrorConfirm error={isErrorSize} hasErrorSizeWithDates={true} onAction={props.onAction}/>

            {/*Ошибка объем + даты*/}
        </>}

        {!isErrorWithCompleted && isErrorSize && !isErrorWithDates && <>
            <ModalErrorConfirm error={isErrorSize} hasErrorSizeWithDates={false} onAction={props.onAction}/>

            {/*Ошибка объем*/}
        </>}

        {props.typeError == TypeModalEnum.contracts && (!errors.length || props.isAgreementContract) &&
            <ModalErrorContractAgreement
                showHistory={!errors.length}
                docId={document2Id}
                contractLines={documentLines}
                onAction={async (action, file_ids) => {
                    try {
                        let messageSuccess = 'Контракт согласован';
                        if (action == 'approve') {
                            await api.contracts.statusCreate({
                                id: document2Id,
                                status: 'on_approval_by_spm',
                                file_ids: []
                            })
                        }

                        if (action == 'approve_spm') {
                            await api.contracts.statusCreate({
                                id: document2Id,
                                status: 'on_approval_by_dk',
                                file_ids: []
                            })
                            messageSuccess = 'Контракт согласован СРП';
                        }

                        if (action == 'approve_hd') {
                            await api.contracts.statusCreate({id: document2Id, status: 'signing', file_ids: []})
                            messageSuccess = 'Контракт согласован ХД';
                        }

                        if (action == 'signing') {
                            await api.contracts.statusCreate({id: document2Id, status: 'signed', file_ids: file_ids})
                            messageSuccess = 'В контракт загружен подписанный документ';
                        }

                        if (action == 'reject') {
                            await api.contracts.statusCreate({id: document2Id, status: 'rejected', file_ids: []})
                            messageSuccess = 'Контракт отклонен';
                            await delay(1000)
                        }

                        clearCacheContractsAgreement();
                        clearCacheContractsStatuses();

                        await loadData(getPassportCache().id ?? '');


                        notification.success({message: messageSuccess})

                        props.onAction('confirm')
                        props.onCancel();
                    } catch (e) {
                        notification.error({message: 'Ошибка'})
                    }
                }}
            />}

        {/*{JSON.stringify(props.errors)}*/}


    </Modal>;
};

export default ModalErrorView;

export function ModalErrorViewBind(props: {
    isOpen: boolean | string,
    errors: ProjectError[],
    onClose: () => void,
    onAction: (type: 'confirm' | 'cancel') => void,
    typeError: TypeModalEnum,
    isAgreementContract: boolean,
}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const {clearCacheContractsAgreement} = useContractsAgreement()
    const {clearCacheContractsStatuses} = useContractsStatuses()

    const handleClose = () => {
        setIsModalOpen(false);
        if (getWData().globalActions) {
            getWData().globalActions.isModalErrors = false;
        }
        props.onClose();
    };

    useEffect(() => {
        if (props.isOpen) {
            setIsModalOpen(true);
        }
    }, [props.isOpen]);


    return <ModalErrorView
                            isAgreementContract={props.isAgreementContract}
                            isOpen={isModalOpen}
                           open={isModalOpen ? props.isOpen : false}
                           onOk={handleClose}
                           onCancel={handleClose}
                           errors={props.errors}
                           onAction={(type) => {
                               props.onAction(type);
                               clearCacheContractsAgreement();
                               clearCacheContractsStatuses();
                               if (type == 'cancel') {
                                   props.onClose();
                                   setIsModalOpen(false);
                               }
                           }}
                           typeError={props.isOpen.toString().includes('_') ? getTypeModalEnum(props.isOpen.toString().split('_')[0]) : props.typeError}
    />;

}
