import { Dropdown } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { FunctionComponent } from 'react';
import { IContragentList } from '../../Models/IContragent';
import tokenActions, { AccessItem } from '../../actions/tokenActions';
import CustomTableSearch from '../../shared/CustomTableSearch';
import HtmlTruncate from '../../shared/HtmlTruncate';
import { stopEvent } from '../../shared/stopEvent';
import { localeKeys } from '../../i18n/localeKeys';
import { flatten, uniq } from 'lodash';

export const ContragentMore: FunctionComponent<{
    onView: (id: string) => void;
    onEdit: (id: string) => void;
    onArchive: (id: string) => void;
    onRestore: (id: string) => void;
    status: 'active' | 'archive';
    record: IContragentList;
    t: (key: string) => string
}> = (props) => {
    if (
        tokenActions.contragents == AccessItem.VIEW_ONLY ||
        tokenActions.contragents == AccessItem.VIEW ||
        tokenActions.contragents == AccessItem.VIEW_ALL
    ) {
        return (
            <span style={{ cursor: 'pointer' }} onClick={(e) => stopEvent(e)}>
                <Dropdown
                    menu={{
                        items: [
                            {
                                key: 'view',
                                label: props.t(localeKeys.shared.viewBtn),
                            },
                        ],
                        onClick: ({ key }) => {
                            if (key == 'view') {
                                props.onView(props.record.id ?? '');
                                return;
                            }
                        },
                    }}
                >
                    <img src="/canvas_table/dots_1.svg" alt={'...'} />
                </Dropdown>
            </span>
        );
    }

    if (tokenActions.contragents != AccessItem.EDIT) {
        return <></>;
    }

    return (
        <span style={{ cursor: 'pointer' }} onClick={(e) => stopEvent(e)}>
            <Dropdown
                menu={{
                    items: [
                        props.status == 'active'
                            ? {
                                key: 'edit',
                                label: props.t(localeKeys.shared.editBtn),
                            }
                            : null,
                        props.status == 'active'
                            ? {
                                key: 'archive',
                                label: props.t(localeKeys.shared.deleteBtn),
                            }
                            : {
                                key: 'restore',
                                label: props.t(localeKeys.shared.restoreBtn),
                            },
                    ],
                    onClick: ({ key }) => {
                        if (key == 'edit') {
                            props.onEdit(props.record.id ?? '');
                            return;
                        }
                        if (key == 'archive') {
                            props.onArchive(props.record.id ?? '');
                            return;
                        }
                        if (key == 'restore') {
                            props.onRestore(props.record.id ?? '');
                            return;
                        }
                    },
                }}
            >
                <img src="/canvas_table/dots_1.svg" alt={'...'} />
            </Dropdown>
        </span>
    );
};
const ContragentListColumns: (props: {
    onView: (id: string) => void;
    onEdit: (id: string) => void;
    onArchive: (id: string) => void;
    onRestore: (id: string) => void;
    status: 'active' | 'archive';
    filtersLocation: { value: string; label: string; }[],
    filtersNomenclatures: { value: string; label: string; }[],
    filtersRating: { value?: string; label?: string }[];
    filterState: { [x: string]: { value: string[]; setValue: (val: string[]) => void } };
    filterSearch: { [x: string]: { value: string; setValue: (text: string) => void } };
    t: (key: string) => string;
}) => ColumnsType<IContragentList> = (props) => [
    {
        title: '№',
        dataIndex: 'row_number',
        width: `50px`,
        onCell: (record, index) => ({ className: `rating_column_${record.rating}` }),
    },
    {
        title: props.t(localeKeys.contragentsListScreen.columns.contragent),
        dataIndex: 'name',
        width: '200px',
        render: (text, record) => {
            const name = (
                <div onClick={(e) => props.onEdit(record.id)}>
                    <div className={'contragent_name'}>{text}</div>
                    {record.inn && <div>{props.t(localeKeys.contragentsListScreen.inn)}: {record.inn}</div>}
                </div>
            );
            if (!record.id) return name;

            return (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        textDecoration: (record as any).error_action ? 'line-through' : '',
                    }}
                    data-test={`passport_${record.name}`}
                >
                    {name}
                    <ContragentMore
                        onView={props.onView}
                        onEdit={props.onEdit}
                        onArchive={props.onArchive}
                        onRestore={props.onRestore}
                        status={props.status}
                        record={record}
                        t={props.t}
                    />
                </div>
            );
        },
        onCell: (record, index) => ({ className: `rating_column_${record.rating}` }),
    },
    {
        title: props.t(localeKeys.contragentsListScreen.columns.nomenclature),
        dataIndex: 'tags',
        width: '200px',
        render: (text, record) => {
            return <>{uniq(props.filtersNomenclatures.filter(e => {
                return !!record.nomenclatures.find(e2 => e2.nomenclature_id == e.value);
            }).map(e => e.label))?.join(', ')}</>;
        },
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
            return (
                <CustomTableSearch
                    filterSearch={props.filterSearch['tags']}
                    filterState={props.filterState['tags']}
                    setSelectedKeys={setSelectedKeys}
                    confirm={confirm}
                    items={props.filtersNomenclatures}
                />
            );
        },
        onCell: (record, index) => ({ className: `rating_column_${record.rating}` }),
    },
    // {
    //     title: t(localeKeys.contragentsListScreen.columns.rating),
    //     dataIndex: 'rating',
    //     width: '105px',
    //     onCell: (record, index) => {
    //         return { className: `rating_column_${record.rating}` };
    //     },
    //     render: (text, record) => {
    //         if (!text) return <></>;
    //         return <>{props.filtersRating.find((e) => e.value == Number(record.rating))?.label}</>;
    //     },
    //     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
    //         return (
    //             <CustomTableSearch
    //                 filterSearch={props.filterSearch['rating']}
    //                 filterState={props.filterState['rating']}
    //                 setSelectedKeys={(val: string[]) => {
    //                     setSelectedKeys(val.map((e) => props.filtersRating.find((e2) => e2.label == e)?.value ?? ''));
    //                 }}
    //                 confirm={confirm}
    //                 items={props.filtersRating.map((e) => e.label ?? '')}
    //             />
    //         );
    //     },
    // },
    {
        title: props.t(localeKeys.contragentsListScreen.columns.region),
        dataIndex: 'location',
        width: '150px',
        render: (text, record) => {
            const allRegionIds = uniq(flatten((record.nomenclatures ?? []).map(e => e.region_ids)));
            return <>{props.filtersLocation.filter(e => allRegionIds.includes(e.value)).map(e => e.label)?.join(', ')}</>;
        },
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
            return (
                <CustomTableSearch
                    filterSearch={props.filterSearch['location']}
                    filterState={props.filterState['location']}
                    setSelectedKeys={setSelectedKeys}
                    confirm={confirm}
                    items={props.filtersLocation}
                />
            );
        },
        onCell: (record, index) => ({ className: `rating_column_${record.rating}` }),
    },
    {
        title: props.t(localeKeys.contragentsListScreen.columns.comments),
        dataIndex: 'comment',
        render: (text) => {
            return <HtmlTruncate html={text ?? ''} maxLines={6} />;
        },
        onCell: (record, index) => ({ className: `rating_column_${record.rating}` }),
    },
    {
        title: props.t(localeKeys.contragentsListScreen.columns.address),
        dataIndex: 'legal_address',
        onCell: (record, index) => ({ className: `rating_column_${record.rating}` }),
    },
    {
        title: props.t(localeKeys.contragentsListScreen.columns.contacts),
        dataIndex: 'contacts',
        render: (text) => {
            return <HtmlTruncate html={text ?? ''} maxLines={6} />;
        },
        onCell: (record, index) => ({ className: `rating_column_${record.rating}` }),
    },
    {
        title: props.t(localeKeys.contragentsListScreen.columns.contactsPnk),
        dataIndex: 'related_user',
        render: (text) => {
            return <HtmlTruncate html={text ?? ''} maxLines={6} />;
        },
        onCell: (record, index) => ({ className: `rating_column_${record.rating}` }),
    },
    // {
    //     title: "Тип",
    //     dataIndex: "type",
    //     render: (text) => {
    //         if (!text) return <></>
    //         return <>{text.name}</>
    //     },
    //     filters: Object.values(props.filters?.types ?? {}).map(e => ({text: e.label, value: e.value})),
    // },
];

export default ContragentListColumns;
