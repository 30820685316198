import {FunctionComponent, useEffect, useState} from 'react';
import {ContragentModalManyForm} from '../../../../Models/ContragentForm';
import {Col, Form, Input, Row} from 'antd';
import { TableRecord, TableRecordContract } from '../TableRecord';
import {sizes} from '../../../../shared/sizes';
import {DeleteOutlined} from '@ant-design/icons';
import {InvoiceFormNomenclatures} from "../../../../Models/InvoiceForm";
import {validateFormMaxMinNumber, validateNumber} from "../utils/validateFormNumber";
import {useTranslation} from "react-i18next";
import {localeKeys} from "../../../../i18n/localeKeys";
import { numberFormatter } from '../../../../shared/dateFormat';
import { getNamePropsWithData, TypeModalEnum } from '../utils/getErrorFieldPrefics';


const ModalAddInvoiceForm: FunctionComponent<{
    item: InvoiceFormNomenclatures,
    contract?: TableRecordContract,
    record: TableRecord,
    onDone: (item: InvoiceFormNomenclatures) => void,
    onRemove: () => void,
    disabledPrice: boolean,
    type: TypeModalEnum
}> = (props) => {
    const {t} = useTranslation();
    const [form] = Form.useForm<ContragentModalManyForm>();
    const nameProp = getNamePropsWithData(props.type)

    const sizeNotContracted = Number(props.contract?.size) - props.record[nameProp]
        .filter((item) => item.parent_contract_id === props.contract?.document_id)
        .reduce((acc, item) => acc + Number(item.size ?? '0'), 0);
    const sumNotContracted = Number(props.contract?.sum_wat) - props.record[nameProp]
        .filter((item) => item.parent_contract_id === props.contract?.document_id)
        .reduce((acc, item) => acc + Number(item.sum_wat ?? '0'), 0);

    const [baseValues, setBaseValues] = useState<ContragentModalManyForm>({
        title: props.record.cns_title ?? '',
        size: `${props.item.volume}`,
        price: `${props.item.price}`,
        sum_wat: `${props.item.amount}`,
    });

    useEffect(() => {
        const val = {
            title: props.record.cns_title ?? '',
            size: `${props.item.volume}`,
            price: `${props.item.price}`,
            sum_wat: `${props.item.amount}`,
        };
        setBaseValues(val)
        form.setFieldsValue(val)
        // console.log(JSON.stringify(val))

    }, [props.item])

    async function update() {
        const res: InvoiceFormNomenclatures = {
            ...props.item,
            volume: Number(form.getFieldValue('size') ?? '0'),
            price: Number(form.getFieldValue('price') ?? '0'),
            amount: Number(form.getFieldValue('sum_wat') ?? '0')
        };
        props.onDone(res)
    }

    const isMobile = window.outerWidth < sizes.mobile;

    (window as any).modalInvoiceForm = {
        baseValues,
        form,
        isMobile,
    }

    return <div className={'modal-add-contract-form'}>
        <div className={'modal-add-contract-form__title'}>
            <div>
                {props.record.cns_title}
            </div>

            <DeleteOutlined onClick={props.onRemove}/>
        </div>

        {/*{JSON.stringify(props.item.title)}*/}
        <Form<ContragentModalManyForm>
            form={form}
            name="basic"
            style={window.innerWidth > sizes.mobile ? { maxWidth: sizes.mobile } : {}}
            initialValues={baseValues}
            onFinish={() => update}
            autoComplete="off"
            labelCol={{ span: 12 }}
            labelAlign={'right'}
            colon={true}
        >
            <div className={'modal-add-contract-form__info'}>
                <div>
                    <span>Контракт:</span> {numberFormatter(props.contract?.size ?? 0, { isTwoDigit: true })} {props.record.cns_ed_izm} × {numberFormatter(props.contract?.price ?? 0, { isTwoDigit: true })} руб
                    = {numberFormatter(props.contract?.sum_wat ?? 0, { isTwoDigit: true })} руб
                </div>
                <div>
                    <span>Осталось:</span> {numberFormatter(sizeNotContracted, { isTwoDigit: true })} {props.record.cns_ed_izm} × {numberFormatter(props.record.cns_budget_plan_price, { isTwoDigit: true })} руб
                    = {numberFormatter(sumNotContracted, { isTwoDigit: true })} руб
                </div>
            </div>

            <Row gutter={isMobile ? { sm: 10 } : undefined}>
                <Col span={isMobile ? 11 : 12}>
                    <Form.Item<ContragentModalManyForm>
                        label={t(localeKeys.table.add.invoice.volume)}
                        name="size"
                        rules={[
                            {
                                message: t(localeKeys.shared.requiredField2),
                                validator: () => validateNumber(form, 'size'),
                            },
                            {
                                validator: () => validateFormMaxMinNumber(form, 'size', {
                                    max: Number.parseFloat(props.record.cns_budget_plan_size),
                                    min: 0,
                                    placeholderErrorMax: t(localeKeys.table.add.invoice.limitExceeded),
                                }),
                            },
                        ]}
                    >
                        <Input
                            data-test="INPUT_INVOICE_SIZE"
                            onChange={() => {
                            setBaseValues({ ...baseValues, size: form.getFieldValue('size') });
                            form.setFieldValue('sum_wat', `${Number(form.getFieldValue('size') ?? '0') * Number(form.getFieldValue('price') ?? '0')}`);
                            update()
                        }} />
                    </Form.Item>
                </Col>
                <Col span={isMobile ? 11 : 12} offset={isMobile ? 2 : 0}>
                    <Form.Item<ContragentModalManyForm>
                        label={t(localeKeys.table.add.contract.price)}
                        name="price"
                        rules={[
                            {
                                message: t(localeKeys.shared.requiredField2),
                                validator: () => validateNumber(form, 'price'),
                            },
                            {
                                validator: () => validateFormMaxMinNumber(form, 'price', {
                                    max: Number.parseFloat(props.record.cns_budget_plan_price),
                                    min: 0,
                                    placeholderErrorMax: t(localeKeys.table.add.invoice.limitExceeded),
                                }),
                            },
                        ]}
                    >
                        <Input
                            data-test="INPUT_INVOICE_PRICE"
                            disabled={props.disabledPrice}
                            onChange={() => {
                                setBaseValues({ ...baseValues, price: form.getFieldValue('price') });
                                form.setFieldValue('sum_wat', `${Number(form.getFieldValue('size') ?? '0') * Number(form.getFieldValue('price') ?? '0')}`);
                                update()
                            }} />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item<ContragentModalManyForm>
                label={t(localeKeys.table.add.contract.sum)}
                labelCol={{ span: 6 }}
                name="sum_wat"
                rules={[
                    {
                        message: t(localeKeys.shared.requiredField2),
                        validator: () => validateNumber(form, 'sum_wat'),
                    },
                    {
                        validator: () => validateFormMaxMinNumber(form, 'sum_wat', {
                            max: Number.parseFloat(props.record.cns_budget_plan_sum_wat),
                            min: 0,
                            placeholderErrorMax: t(localeKeys.table.add.invoice.limitExceeded),
                        }),
                    }
                ]}
            >
                <Input
                    data-test="INPUT_INVOICE_SUM"
                    onChange={() => {
                    setBaseValues({ ...baseValues, sum_wat: form.getFieldValue('sum_wat') });
                    update()
                }} />
            </Form.Item>
        </Form>
    </div>;
};

export default ModalAddInvoiceForm;
