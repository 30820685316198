export async function pasteRows(): Promise<string[][]> {
  return new Promise((resolve) => {
    navigator.clipboard.read().then(async (data) => {
      console.log(data);
      const blob = await data[0].getType('text/html');
      const text = await blob.text();
      console.log(text);
      const element = document.createElement('div');
      element.innerHTML = text;
      const rows = element.querySelectorAll('tr');
      const res: string[][] = [];

      rows.forEach((row) => {
        const resRow = [];
        const cells = row.querySelectorAll('td');
        for (let i = 0; i < cells.length; i++) {
          let line = (cells[i].textContent || '').trim();
          if (i > 1) {
            line = line
              .split('')
              .filter((e) => (e >= '0' && e <= '9') || e == '.' || e == ',')
              .join('');
            if (line.includes(',')) {
              line = line.replaceAll('.', '');
              line = line.replaceAll(',', '.');
            }
          }
          resRow.push(line);
        }
        res.push(resRow);
      });

      console.log(res);

      resolve(res);
    });
  });
}
