import { ColumnsType } from 'antd/es/table';
import { TableRow } from '../../ApiDirectory';
import { Dropdown, Select } from 'antd';

export function contragentListModalId(record: TableRow) {
    switch (record.row_type) {
        case 'section':
            return record.section_id!;
        case 'work_type':
            return `${record.section_id!}_${record.type_id!}`;
        case 'nomenclature':
            return `${record.section_id!}_${record.type_id!}_${record.nomenclature_id!}`;
    }

    return '';
}

const contragentListModalColumns: (props: {
    expandedRowKeys: string[],
    setExpandedRowKeys: (keys: string[]) => void;
    items: TableRow[],
    locations: { id: string, title: string }[]
    handleRemoveNomenclature: (record: TableRow) => void,
    updateLocationsByNomenclature: (record: TableRow, locationIds: string[]) => void,
    stateNomenclatures: { [nomenclature_id: string]: { locationIds: string[] } };
}) => ColumnsType<TableRow & { hasChildren: boolean }> = (props) => {
    return [
        {
            title: 'Наименование',
            width: '300px',
            render: (text, record) => {
                const id: string = record.nomenclature_id || record.type_id || record.section_id as string;
                let fullId = ``;

                if (record.row_type == 'section') {
                    fullId = record.section_id!;
                } else if (record.row_type == 'work_type') {
                    fullId = `${record.section_id!}_${record.type_id!}`;
                } else {
                    fullId = `${record.section_id!}_${record.type_id!}_${record.nomenclature_id!}`;
                }

                const isExpanded = props.expandedRowKeys.includes(fullId);

                return <div className={`nomenclatures-table__title ${record.row_type}`}>
                    {record.row_type == 'section' && record.hasChildren ? (!isExpanded ?
                        <img className={'icon'} src={'/canvas_table/icon_plus.svg'} alt="плюс" /> :
                        <img className={'icon'} src={'/canvas_table/icon_remove.svg'}
                             alt="минус" />) : null}
                    {record.row_type == 'section' && !record.hasChildren ? (!isExpanded ?
                        <img className={'icon'} src={'/canvas_table/icon_plus_disabled.svg'}
                             alt="плюс" /> :
                        <img className={'icon'} src={'/canvas_table/icon_remove_disabled.svg'}
                             alt="минус" />) : null}

                    {record.row_type == 'work_type' && record.hasChildren ? (!isExpanded ?
                        <img className={'icon'} src={'/canvas_table/icon_plus.svg'} alt="плюс" /> :
                        <img className={'icon'} src={'/canvas_table/icon_remove.svg'}
                             alt="минус" />) : null}
                    {record.row_type == 'work_type' && !record.hasChildren ? (!isExpanded ?
                        <img className={'icon'} src={'/canvas_table/icon_plus_disabled.svg'}
                             alt="плюс" /> :
                        <img className={'icon'} src={'/canvas_table/icon_remove_disabled.svg'}
                             alt="минус" />) : null}

                    <span className={'nomenclatures-table__title-text'}>
                        &nbsp;{record.title}
                        {record.row_type == 'nomenclature' && props.items.filter(e => e.nomenclature_id == record.nomenclature_id).length > 1 &&
                            <img src={`/canvas_table/icon_share.svg`} alt={'Продублирована'}
                                 className={'icon-share'} />}
                    </span>

                    <Dropdown
                        className={'nomenclatures-table__actions'}
                        placement="bottomLeft"
                        menu={{
                            items: [{
                                key: '1',
                                label: <> Удалить </>,
                                onClick: () => {
                                    props.handleRemoveNomenclature(record);
                                },
                            }],
                        }}
                    >
                        <img src={'/canvas_table/dots_1.svg'} alt={'more'} />
                    </Dropdown>
                </div>;
            },
        },
        {
            title: 'Регион',
            render: (text, record) => {
                if (record.row_type == 'nomenclature')
                    return <span className={`${record.row_type}`}>
                        <Select
                            key={'select_' + contragentListModalId(record)}
                            options={props.locations.map(item => ({
                                label: item.title,
                                value: item.id,
                            }))}
                            mode="multiple"
                            filterOption={(value, option) => option?.label?.toLowerCase().includes(value.toLowerCase()) ?? false}
                            onChange={value => {
                                props.updateLocationsByNomenclature(record, value);
                            }}
                            value={props.stateNomenclatures[contragentListModalId(record)].locationIds}
                        />
                    </span>;

                return <span className={`${record.row_type}`}></span>;
            },
        },
    ];
};

export default contragentListModalColumns;
