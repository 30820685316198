import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { getWData } from '../../../table_extends/getWData';
import { Button, Col, Form, Input, Modal, notification, Row, TreeSelect } from 'antd';
import { api } from '../../../../shared/api_client';
import moment from 'moment/moment';
import { getEmptyTableRecord, getEmptyTableRecordContract, TableRecord, TableRecordContract } from '../TableRecord';
import { getPassportCache } from '../../../table_extends/loadData';
import { MyDatePicker } from '../../../../shared/stopEvent';
import { Select } from 'antd/lib';
import { filterOption } from './modal_add_contract';
import ModalAddInvoiceForm from './modal_add_invoice_form';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { getTreeRows3 } from '../../../../shared/getTreeRows3';
import { modalFilterContracts, modalFilterContracts2 } from '../../../../shared/modal/modalFilterContracts';
import { modalFilterContragents } from '../../../../shared/modal/modalFilterContragents';
import ModalAddContractUpload from './modal_add_contracts_upload';
import tokenActions, { AccessItem } from '../../../../actions/tokenActions';
import {
    getEmptyIAWayBills,
    IApiWayBills,
    IWayBills,
    WayBillsFormNomenclatures,
} from '../../../../Models/WayBillsForm';
import { DateFormat, dateFormatter } from '../../../../shared/dateFormat';
import enUS from 'antd/es/calendar/locale/en_US';
import { addProjectDocumentDb, DocumentType, isOnline, updateProjectTableRowDb } from '../../../../indexedb';
import ModalWarning from './modal_warning';
import { sizes } from '../../../../shared/sizes';
import { useTranslation } from 'react-i18next';
import { localeKeys } from '../../../../i18n/localeKeys';
import { mapNomenclature2InvoiceNomenclature } from '../../../../Models/InvoiceForm';
import { delay } from '../../../../shared/delay';
import { TypeModalEnum } from '../utils/getErrorFieldPrefics';


interface IModalAddGlobal {
    open: boolean | string | undefined,
    onOk: ( item: IWayBills ) => void | undefined,
    onCancel: () => void | undefined
}

const ModalAddWayBills: FunctionComponent<IModalAddGlobal> = ( props ) => {
    const { t } = useTranslation();
    const [ isSending, setIsSending ] = useState( false );
    const [ state, setState ] = useState<IWayBills>( getEmptyIAWayBills() );
    const [ filteredContractsByNomenclature, setFilteredContractsByNomenclature ] = useState<TableRecordContract[]>( [] );
    const [ filteredContractsByContragents, setFilteredContractsByContragents ] = useState<TableRecordContract[]>( [] );

    const [ filteredContragents, setFilteredContragents ] = useState<DefaultOptionType[]>( [] );

    // первый фильтр по номенклатуре
    const [ selectNomenclatures, setSelectNomenclatures ] = useState<string[]>( [] );
    const [ tree, setTree ] = useState<DefaultOptionType[]>( [] );

    const rows3 = getWData().rows3;

    const selectedContractByNomenclature = useMemo( () => {
        const contract = filteredContractsByContragents.find( e => e.id == state.contract_id );
        const docId = contract?.document_id ?? '';

        return rows3
            .filter( e => selectNomenclatures.includes( e.cns_nomenclature_id ?? '' ) )
            .map( e => e.cns_contracts.find( e => e.document_id == docId ) )
            .flat();
    }, [ state, filteredContractsByContragents ] );


    function refreshContragents( nomenclatureIds: string[] ) {
        // console.log('refreshContragents', nomenclatureIds);

        const res = modalFilterContragents( nomenclatureIds, rows3 );

        setFilteredContragents( res );

        return Object.values( res );
    }

    function refreshContracts( nomenclatureIds: string[], contractor_id?: string ) {
        const filteredIds = filteredContragents.map( e => `${ e.value }` );

        const {
            listByContragents,
            listByNomenclature
        } = modalFilterContracts( nomenclatureIds, contractor_id, filteredIds );

        setFilteredContractsByContragents( Object.values( listByContragents ) );
        setFilteredContractsByNomenclature( Object.values( listByNomenclature ) );

        return Object.values( listByContragents );
    }

    function resetState() {
        refreshContracts( [] );
        refreshContractsByNomenclature( [] )

        setState( getEmptyIAWayBills() );

        setIsSending( false );
        setSelectNomenclatures( [] );
        setFilteredContractsByContragents( [] );
        setFilteredContractsByNomenclature( [] )
        setFilteredContragents( [] );

        setTree( getTreeRows3( 'waybill' ) );

    }

    async function handleSave() {
        setIsSending( true );

        const payload: IApiWayBills = {
            waybill_date: moment( state.waybill_date, 'YYYY-MM-DD' ).format( 'DD.MM.YYYY' ),
            waybill_number: state.waybill_number,
            nomenclatures: state.nomenclatures.map( e => ({
                nomenclature_id: e.nomenclature_id,
                price: e.price,
                volume: e.volume,
                amount: e.amount,
                type_id: e.type_id,
                project_id: e.project_id,
                section_id: e.section_id,
            }) ),
            contract_id: state.document_id,
            file_ids: state.files.map( e => e.id ),
        };

        if ( isOnline ) {
            try {
                const res = await api.documents.waybillsCreate( payload );

                resetState();
                props.onOk( state );

                notification.success( { message: t( localeKeys.table.add.wayBill.created ) } );
            } catch ( e ) {
                notification.error( { message: (e as any)?.error?.message ?? t( localeKeys.table.add.wayBill.createError ) } );
            }
        } else {
            const contractor = `${ filteredContragents.find( e => e.value == state.contractor_id )?.label }`;
            const id = Math.random().toString();
            await addProjectDocumentDb( id, DocumentType.waybill, {
                ...payload,
                contractor_id: state.contractor_id ?? '',
                contractor: contractor,
                files: state.files,
            } );

            for ( let item of state.nomenclatures ) {
                const row = rows3.find( e => e.cns_nomenclature_id == item.nomenclature_id ) ?? getEmptyTableRecord();

                row.cns_way_bills.push( {
                    id: id,
                    project_contract_data_id: state.contract_id ?? '',
                    document_id: Math.random().toString(),

                    parent_id: row.cns_nomenclature_id ?? '',

                    parent_contract_id: filteredContractsByContragents.find( e => e.contragent_id == state.contractor_id )?.document_id ?? '',

                    contragent: contractor,
                    contragent_id: state.contractor_id ?? '',

                    status: '',

                    size: `${ item.volume }`,
                    price: `${ item.price }`,
                    sum_wat: `${ item.amount }`,

                    doc_number: state.waybill_number,
                    doc_date: state.waybill_date,

                    files: state.files.length,

                    documents: state.files.map( file => ({
                        id: file.id,
                        name: file.name,
                        url: file.id,
                        path: file.id,
                    }) ),

                    date_start: '',
                    date_end: '',
                } )

                row.cns_well_bill_doc_number = row.cns_way_bills.length > 1 ? row.cns_way_bills.length : payload.waybill_number as any
                row.cns_well_bill_delivery_sum = row.cns_way_bills.reduce( ( sum, waybill ) => sum + Number( waybill.sum_wat ), 0 )
                row.cns_well_bill_delivery_date = state.waybill_date

                await updateProjectTableRowDb( getPassportCache().id ?? '', row );
            }


            notification.info( { message: t( localeKeys.table.add.wayBill.saveOfflineWarning ) } );

            getWData().setRefresh( Math.random() );
            resetState();
            props.onOk( state );
        }

        setIsSending( false );
    }

    function handleRemove( id: string ) {
        setState( { ...state, nomenclatures: state.nomenclatures.filter( item => item.nomenclature_id !== id ) } );
        setSelectNomenclatures( selectNomenclatures.filter( item => item !== id ) );
    }


    function onChangeTree( nomenclatureIds: string | string[] ) {
        if ( Array.isArray( nomenclatureIds ) ) {
            setSelectNomenclatures( nomenclatureIds );

            refreshContracts( nomenclatureIds );
            refreshContractsByNomenclature( nomenclatureIds );
        }

        setState( { ...state, nomenclatures: [], contractor_id: undefined, contract_id: undefined, files: [] } );
    }

    function updateWayBillLines( contract_id: string ) {
        console.log( 'updateWayBillLines', contract_id );
        const allContracts = rows3.map( item => item.cns_contracts ).flat();
        const contract = allContracts.find( e => e.id == contract_id );
        const nomenclatures: TableRecord[] = rows3
            .filter( e => e.cns_contracts.find( e2 => e2.document_id == contract?.document_id ) && selectNomenclatures.includes( e.cns_nomenclature_id ?? '' ) );

        const allContracts2 = nomenclatures.map( item => item.cns_contracts.find( e => e.document_id == contract?.document_id ) ?? getEmptyTableRecordContract() );

        const nomenclatures2: WayBillsFormNomenclatures[] = allContracts2
            .map( ( e, index ) => mapNomenclature2InvoiceNomenclature( nomenclatures[index], e as any, t as any ) );

        setState( {
            ...state,
            contract_id: contract_id,
            contractor_id: contract?.contragent_id ?? '',
            document_id: contract?.document_id ?? '',
            nomenclatures: nomenclatures2,
        } );

    }

    const isDisabled = !state.waybill_number || !state.waybill_date || !state.contract_id || state.nomenclatures.length == 0 || state.files.length == 0;

    useEffect( () => {
        resetState();
        delay( 100 ).then( async _ => {
            if ( props.open && typeof props.open === 'string' ) {
                const nomenclature = getWData().rows3.find( e => e.cns_contracts.find( e2 => e2.id == props.open ) );
                const contract = nomenclature?.cns_contracts.find( e => e.id == props.open && e.status === 'signed' );

                if ( nomenclature && contract ) {
                    setSelectNomenclatures( [ nomenclature.cns_nomenclature_id ?? '' ] );

                    refreshContracts( [ nomenclature.cns_nomenclature_id ?? '' ], `${ contract.contragent_id }` );
                    refreshContractsByNomenclature( [ nomenclature.cns_nomenclature_id ?? '' ] );
                    setState( {
                        ...state,
                        document_id: contract.document_id,
                        contract_id: contract?.id,
                        contractor_id: contract?.contragent_id ?? '',
                        nomenclatures: [
                            mapNomenclature2InvoiceNomenclature( nomenclature, contract, t as any ),
                        ],
                    } );
                }
            }
        } )
    }, [ props.open ] );

    function refreshContractsByNomenclature( selectNomenclatures: string[] ) {
        const contragents = refreshContragents( selectNomenclatures );

        if ( contragents?.length == 1 ) {
            setState( { ...state, contractor_id: `${ contragents[0].value }` } );
            refreshContracts( selectNomenclatures, `${ contragents[0].value }` );
        } else {
            setState( { ...state, contractor_id: undefined } );
            refreshContracts( selectNomenclatures, undefined );
        }
    }

    useEffect( () => {
        if ( selectNomenclatures && state.contractor_id ) {
            refreshContracts( selectNomenclatures, state.contractor_id );

            const contracts = refreshContracts( selectNomenclatures, `${ state.contractor_id }` );

            if ( contracts?.length == 1 ) {
                updateWayBillLines( contracts[0].id );
            }
        }
    }, [ state.contractor_id ] );

    const hasQuotaExceeded = state.nomenclatures.filter( nomenclature => {
        const record = rows3.find( e2 => nomenclature.nomenclature_id === e2.cns_nomenclature_id ) ?? getEmptyTableRecord();
        const contract = record.cns_contracts.find( e2 => e2.document_id == state.document_id ) ?? getEmptyTableRecordContract();

        const wayBills = (record.cns_way_bills ?? []).filter( e => e.parent_contract_id == state.document_id );
        const wayBillsSize = wayBills.reduce( ( sum, invoice ) => sum + Number( invoice.size ), 0 );
        const wayBillsPrice = wayBills.reduce( ( sum, invoice ) => sum + Number( invoice.price ), 0 ) / wayBills.length;
        const wayBillsSum = wayBills.reduce( ( sum, invoice ) => sum + Number( invoice.sum_wat ), 0 );

        if ( nomenclature.volume + wayBillsSize > Number( contract.size ) ) return true;
        if ( ((nomenclature.price + wayBillsPrice) / 2) > Number( contract.price ) ) return true;
        if ( nomenclature.amount + wayBillsSum > Number( contract.sum_wat ) ) return true;

        return false;

        return false;
    } ).length > 0;

    const isMobile = window.outerWidth < sizes.mobile;

    (window as any).modalAddWayBill = {
        state: state,
        isDisabled: isDisabled,
        tree: tree,
        selectNomenclatures: selectNomenclatures,
        isSending: isSending,
        filteredContragents: filteredContragents,
        filteredContractsByContragents: filteredContractsByContragents,
        filteredContractsByNomenclature: filteredContractsByNomenclature,
        hasQuotaExceeded,
        isMobile,
        selectedContractByNomenclature,
        props,
    };

    const btnCreate = <Button
        data-test={ 'BTN_ADD_WAYBILL' }
        type={ 'primary' } onClick={ hasQuotaExceeded ? undefined : handleSave } disabled={ isDisabled }
        loading={ isSending }> { t( localeKeys.table.add.wayBill.save ) } </Button>;


    return <>
        <Modal title={ t( localeKeys.table.add.wayBill.title ) }
               open={ !!props.open }
               onCancel={
                   () => {
                       resetState();
                       props.onCancel();
                   } }
               destroyOnClose={ true }
               className="modal-add-contract"
               footer={ <>
                   <Button
                       type={ 'link' }
                       onClick={ () => {
                           resetState();
                           props.onCancel();
                       } }> { t( localeKeys.shared.cancelBtn ) } </Button>
                   { hasQuotaExceeded && !isDisabled ?
                       <ModalWarning
                           btn={ btnCreate }
                           text={ t( localeKeys.table.add.wayBill.quotaExceeded ) }
                           onOk={ handleSave }
                           onCancel={ () => {
                           } }
                       />
                       : btnCreate }

               </> }
        >
            <Form layout={ isMobile ? 'vertical' : 'horizontal' }>
                <Row gutter={ isMobile ? { sm: 10 } : undefined }>
                    <Col span={ isMobile ? 24 : 14 }>
                        <Form.Item
                            label={ t( localeKeys.table.add.invoice.number ) + ':' }
                            labelCol={ { span: 10 } }
                        >
                            <Input
                                data-test={'INPUT_WAYBILL_NUMBER'}
                                placeholder={ t( localeKeys.table.add.invoice.number ) } onInput={ val => {
                                setState( { ...state, waybill_number: (val.target as any).value } );
                            } }/>
                        </Form.Item>
                    </Col>
                    <Col span={ isMobile ? 24 : 10 }>
                        <Form.Item label={ t( localeKeys.table.add.invoice.date ) } labelCol={ { span: 6 } }>
                            <MyDatePicker
                                data-test={'INPUT_WAYBILL_DATE'}
                                locale={ enUS }
                                style={ { maxWidth: isMobile ? '100%' : '137' } }
                                format={ DateFormat }
                                defaultValue={ moment( state.waybill_date, 'YYYY-MM-DD' ) }
                                onChange={ ( dates, dateStrings ) => {
                                    setState( {
                                        ...state,
                                        waybill_date: moment( `${ dateStrings }`, DateFormat ).format( 'YYYY-MM-DD' )
                                    } );
                                } }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={ 24 }>
                        <Form.Item label={ t( localeKeys.table.add.invoice.nomenclatures ) } labelCol={ { span: 6 } }>
                            <TreeSelect
                                data-test={'INPUT_WAYBILL_NOMENCLATURES'}
                                placeholder={ t( localeKeys.table.add.invoice.selectRows ) }
                                treeCheckable={ true }
                                showSearch
                                value={ selectNomenclatures }
                                dropdownStyle={ { maxHeight: 400, overflow: 'auto' } }
                                allowClear
                                treeDefaultExpandAll={ getWData().rows3.length < 100 }
                                onChange={ onChangeTree }
                                treeData={ tree }
                                filterTreeNode={ ( input, treeNode ) => !!treeNode.label?.toString().toLowerCase().includes( input.toLowerCase() ) }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={ 24 }>
                        <Form.Item label={ t( localeKeys.table.add.invoice.contractor ) } labelCol={ { span: 6 } }>
                            <Select
                                data-test={'INPUT_WAYBILL_CONTRACTOR'}
                                showSearch
                                placeholder=""
                                optionFilterProp="children"
                                value={ state.contractor_id }
                                disabled={ selectNomenclatures.length == 0 }
                                filterOption={ filterOption }
                                options={ filteredContragents.map( e => {
                                    const { listByContragents } = modalFilterContracts2( selectNomenclatures, `${ e.value }` );

                                    return ({
                                        label: Object.values( listByContragents ).length ? t( localeKeys.table.add.invoice.labelWithCount, {
                                            label: e.label,
                                            count: Object.values( listByContragents ).length
                                        } ) : t( localeKeys.table.add.invoice.labelWithoutCount, { label: e.label } ),
                                        value: e.value,
                                    });
                                } ) ?? [] }
                                onChange={ val => {
                                    setState( {
                                        ...state,
                                        contractor_id: val,
                                        contract_id: undefined,
                                        nomenclatures: []
                                    } );
                                } }
                            />
                        </Form.Item>
                    </Col>
                    <Col span={ 24 }>
                        <Form.Item label={ t( localeKeys.table.add.invoice.contract ) } labelCol={ { span: 6 } }>
                            <Select
                                data-test={'INPUT_WAYBILL_CONTRACT'}
                                showSearch
                                placeholder=""
                                optionFilterProp="children"
                                value={ state.contract_id }
                                options={ filteredContractsByContragents.map( ( item ) => ({
                                    value: item.id ?? '',
                                    label: item.doc_number ? t( localeKeys.table.add.invoice.titleDoc, {
                                        doc_number: item.doc_number,
                                        doc_date: dateFormatter( item.doc_date ),
                                        sum_wat: item.sum_wat
                                    } ) : `${ item.doc_date } ${ item.size }x${ item.price }, ${ item.sum_wat }`,
                                }) ) }
                                disabled={ state.contractor_id == undefined }
                                filterOption={ filterOption }
                                onChange={ updateWayBillLines }
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            { state.nomenclatures.length != 0 && <div className={ 'modal-add-contract__list' }>
                { state.nomenclatures.map( ( e, index ) => <>
                    <ModalAddInvoiceForm
                        key={ `ModalAddInvoice_${ e.nomenclature_id }` }
                        item={ e }
                        contract={ selectedContractByNomenclature[index] }
                        record={ rows3.find( e2 => e.nomenclature_id === e2.cns_nomenclature_id ) ?? getEmptyTableRecord() }
                        type={ TypeModalEnum.way_bills }
                        onDone={ ( line ) => {
                            setState( {
                                ...state, nomenclatures: state.nomenclatures.map( item => {
                                    if ( item.nomenclature_id === line.nomenclature_id ) {
                                        return line;
                                    }
                                    return item;
                                } ),
                            } )
                        } }
                        onRemove={ () => handleRemove( e.nomenclature_id ?? '' ) }
                        disabledPrice={ true }
                    />
                </> ) }
            </div> }

            <Row>
                <Col span={ isMobile ? 24 : 18 } offset={ isMobile ? 0 : 6 }>
                    <ModalAddContractUpload
                        width={ '170px' }
                        files={ state.files }
                        onUploaded={ fileUploaded => {
                            setState( { ...state, files: fileUploaded } );
                        } }
                        disabled={ tokenActions.contracts != AccessItem.EDIT && tokenActions.contracts != AccessItem.FULL }
                    />
                </Col>
            </Row>

        </Modal>

    </>
};

export default ModalAddWayBills;

export function ModalAddWayBillsBind( props: { isOpen: boolean | string } ) {
    const [ isModalOpen, setIsModalOpen ] = useState<boolean | string>( false );
    const showModal = () => {
        setIsModalOpen( props.isOpen || true );
    };
    const handleCancel = () => {
        setIsModalOpen( false );
        getWData().globalActions.isModalAddWayBills = false;
    };
    const handleOk = () => {
        getWData().globalActions.isModalAddWayBills = false;
        setIsModalOpen( false );
    };

    useEffect( () => {
        if ( props.isOpen ) {
            showModal();
        }
    }, [ props.isOpen ] );

    return <ModalAddWayBills open={ isModalOpen } onOk={ handleOk } onCancel={ handleCancel }/>;
}
